import './App.css';
import Login from './Container/Login/login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './Container/Dashboard/dashboard';
import Artists from './Container/Artists/artists';
import ArtWorks from './Container/ArtWorks/artworks';
import AddArtists from './Container/AddArtist/addArtist';
import ArtistProfile from './Container/ArtistProfile/artistProfile';
import Admins from './Container/Admins/admin';
import AddAdmin from './Container/AddAdmin/addAdmin';
import AdminProfile from './Container/AdminProfile/adminProfile';
import Settings from './Container/Settings/settings';
import Notifications from './Container/Notification/notification';
import AddArt1 from './Container/addArt1/addArt1';
import AddArt2 from './Container/addArt2/addArt2';
import ArtProfile from './Container/ArtProfile/artProfile';
import AddExhibition from './Container/AddExhibition/addExhibition';
import ExhibitionProfile from './Container/ExhibtionProfile/exhibitionProfile';
import CollectionProfile from './Container/CollectionProfile/collectionProfile';
import { useEffect, useState } from 'react';

function App() {
  const [currentMenu, setCurrentMenu] =  useState("dashboard");
  const [currentUser, setCurrentUser] = useState({})
  const [currentArtist, setCurrentArtist] = useState({});
  const [currentCollectionAddress, setCollectionAddress] = useState("")
  const [currentAdmin, setCurrentAdmin] = useState({});
  const [accessToken, setAccessToken] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [Arts, setArts] = useState([]);
  const [collections, setCollections] = useState("")
  const [exhibitions, setExhibitions] = useState([])

  useEffect(()=>{

      fetch("https://creath.tech/api/arts")
      .then(response => response.json())
      .then((data)=>{
        setArts(data.data)
        localStorage.setItem('arts',JSON.stringify(data.data))
      })
      .catch((e)=>{
        console.log(e)
      })
    

      fetch("https://creath.tech/api/collections")
      .then(response => response.json())
      .then((data)=>{
        setCollections(data.data)
        localStorage.setItem('collections',JSON.stringify(data.data))
      })
      .catch((e)=>{
        console.log(e)
      })


      fetch("https://creath.tech/api/exhibitions")
      .then(response => response.json())
      .then((data)=>{
        setExhibitions(data.data)
        localStorage.setItem('exhibitions', JSON.stringify(data.data))
      })
      .catch((e)=>{
        console.log(e)
      })
    
    
  },[])

  const testLogin = localStorage.getItem("login")
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login setAccessToken = {setAccessToken} setIsLoggedIn = {setIsLoggedIn} setCurrentUser = {setCurrentUser}/>}/>
          {
            isLoggedIn || testLogin === "true" ?
            <>
              <Route path="/dashboard" element={<Dashboard currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} setCurrentArtist = {setCurrentArtist} setIsLoggedIn = {setIsLoggedIn} Arts={Arts} />}/>
              <Route path="/artists" element={<Artists currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} setCurrentArtist = {setCurrentArtist} Arts={Arts} />}/>
              <Route path="/addArtist" element={<AddArtists currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} currentUser = {currentUser} setCurrentUser = {setCurrentUser} />}/>
              <Route path='/notifications' element={<Notifications currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} />}/>
              <Route path='/artistProfile'  element={<ArtistProfile currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} currentArtist = {currentArtist} setCurrentArtist = {setCurrentArtist} Arts = {Arts} Collections = {collections} currentUser = {currentUser} setCurrentUser = {setCurrentUser} />}/>
              <Route path='/adminProfile'  element={<AdminProfile currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} currentAdmin = {currentAdmin} setCurrentAdmin = {setCurrentAdmin} currentUser = {currentUser} setCurrentUser = {setCurrentUser} />}/>
              <Route path='/admins' element={<Admins currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} setCurrentAdmin= {setCurrentAdmin} />}/>
              <Route path="/addAdmin" element={<AddAdmin currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} currentUser = {currentUser} setCurrentUser = {setCurrentUser} />}/>
              <Route path="settings" element={<Settings currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} currentUser = {currentUser} setCurrentUser = {setCurrentUser} setIsLoggedIn = {setIsLoggedIn} />}/>
              <Route path='/artWorks' element={<ArtWorks currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} Arts = {Arts} Collections = {collections} Exhibitions = {exhibitions}/>} />
              <Route path='/addArt1' element={<AddArt1 currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} setCollectionAddress = {setCollectionAddress} />} />
              <Route path="/addArt2" element={<AddArt2 currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} currentCollectionAddress = {currentCollectionAddress}  />}/>
              <Route path="/artProfile" element={<ArtProfile currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} currentCollectionAddress = {currentCollectionAddress}  />}/>
              <Route path="/exhibitionsProfile" element={<ExhibitionProfile currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} currentCollectionAddress = {currentCollectionAddress} collections = {collections}  />}/>
              <Route path="/collectionsProfile" element={<CollectionProfile setCollectionAddress = {setCollectionAddress} currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} currentCollectionAddress = {currentCollectionAddress} Arts = {Arts}  />}/>
              <Route path='/addExhibition' element={<AddExhibition currentMenu = {currentMenu} setCurrentMenu = {setCurrentMenu} setCollectionAddress = {setCollectionAddress} />} />
            </>
            :
            <></>
          }   
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
