import React, { useState,useEffect } from "react";
import MainSideBar from "../../Components/Sidebar/sidebar";
import './addArtist.css';
import defaultImage from './default_Image.svg'
import { CountryDropdown, RegionDropdown} from 'react-country-region-selector';
import { useNavigate } from 'react-router-dom';



const AddArtists = (props)=>{
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [profileImage, setProfileImage]  = useState(null);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [address, setAddress] = useState("")
    const [bio, setBio] = useState("");
    const [username, setUserName] = useState("");
    const [twitter, setTwitter] = useState("");
    const [instagram, setInstagram] = useState("");
    const [wallet, setWallet] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [importantEmpty, setImportantEmpty] = useState(true)
    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState(false)
    const [ErrorMessage, setErrorMessage] = useState("Something went wrong, please try again");
    const [user, setUser] = useState("");
    const fileHandler = (e) => {
        setProfileImage(e.target.value)
    }



    useEffect(()=>{
        props.setCurrentMenu("artists")
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if(Object.keys(props.currentUser).length === 0){
            setUser(currentUser)
        }
        else{
            setUser(currentUser)
        }
    },[])

    const removeProfileImage = ()=>{
        setProfileImage(null)
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    let navigate = useNavigate();

    const changePage = (n)=>{
        navigate(n)
    }


    var data = new FormData()
    data.append("full_name", name)
    data.append("email", email)
    data.append("address", address)
    data.append("bio", bio)
    data.append("username", username)
    data.append("country", country)
    data.append("state", state)
    data.append("role","artist")
    if(profileImage !== null){
        data.append("profile_image", profileImage)
    }
    data.append("twitter", twitter)
    data.append("instagram", instagram)
    data.append("wallet",wallet)


    const handleSubmitClick = ()=>{
        setLoading(true)
        
        fetch("https://creath.tech/api/users", {
            method: "POST",
            headers:{
                Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            body: data
        })
        .then((response) => {return response.json()})
        .then((data)=>{
            if(data.message){
                setLoading(false)
                changePage("/artists")
                let notData = new FormData
                notData.append('name',`${JSON.parse(localStorage.getItem("currentUser")).full_name}`)
                notData.append('email',`${ JSON.parse(localStorage.getItem("currentUser")).email}`)
                notData.append('action', "Added an Artist")
                fetch('https://creath.tech/api/notifications',{
                    method: "POST",
                    headers:{
                        Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
                    },
                    body: notData
                }).then(response => response.json())
                .then((data)=>{console.log(data)})
            }
            else if(data.error){
                setFormError(true)
                setLoading(false)
                setErrorMessage(data.error.email[0])
            }
        })
        .catch((e)=>{
            console.log(e)
            setLoading(false)
            setFormError(true)
        })
    }

    const handleEmailChange = (e)=>{
        if(isValidEmail(e.target.value)){
            setEmail(e.target.value)
            setEmailError(false)
            setImportantEmpty(false)
        }
        else if(e.target.value === ""){
            setEmailError(false)
            setImportantEmpty(true)
        }
        else{
            setEmailError(true)
        }
    }

    const handleNameChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true);
        }
        else{
            setName(e.target.value);
        }
    }

    const handleUserNameChange = (e)=>{
        setUserName(e.target.value )
    }

    const handleBioChange = (e)=>{
        setBio(e.target.value)
    }

    const handleAddressChange = (e)=>{
        setAddress(e.target.value);
    }

    const handleTwitterChange = (e)=>{
        setTwitter(e.target.value);
    }

    const handleInstagramChange = (e)=>{
        setInstagram(e.target.value);
    }

    const handleWalletChange = (e)=>{
        setWallet(e.target.value);
    }

    return(
        <div className="mainAddArtists">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="AddArtistsSubMain">
                <div className="AddArtistsHeader">
                    <p>Add Artist</p>
                </div>
                <div className="addArtistFloW">
                    <p><span>Artist > </span>Add Artist</p>
                </div>
                <div className="addArtistForm">
                    <div className="Form1">
                        <div className="input1">
                            <p>Full Name*</p>
                            <input onChange={handleNameChange} type="text" placeholder="Enter your full name"></input>
                        </div>
                        <div className="input1">
                            <p>Email*</p>
                            <input onChange={handleEmailChange} type="email" placeholder="Enter your email"></input>
                            {emailError && <p style={{color: 'red', fontSize: '0.8vw', fontFamily: 'Manrope'}} >Enter A valid email</p>}
                        </div>
                        <div className="input1">
                            <p>Username*</p>
                            <input onChange={handleUserNameChange} type="text" placeholder="Enter a username"></input>
                        </div>
                        <div className="input1">
                            <p>Bio</p>
                            <input onChange={handleBioChange} type="text" placeholder="Enter bio"></input>
                        </div>
                        <div className="input1">
                            <p>USDC Wallet*</p>
                            <input onChange={handleWalletChange} type="text" placeholder="Enter wallet address"></input>
                        </div>
                        <div className="input1">
                            <p>Profile Image Link</p>
                            <input onChange={fileHandler} type="text" placeholder="Enter link to profile image"></input>
                        </div>
                        <div className="input1">
                            <p>Country</p>
                            <CountryDropdown
                                value={country}
                                onChange={(val) => setCountry(val) }
                            />
                        </div>
                        <div className="input1">
                            <p>State</p>
                            <RegionDropdown
                                blankOptionLabel = "No country selected"
                                value={state}
                                country={country}
                                onChange={(val) => setState(val) }
                            />
                        </div>
                    </div>
                    <div className="Form2">
                        <div className="input2">
                            <p>Twitter</p>
                            <input onChange={handleTwitterChange} type="text" placeholder="Twitter Profile"></input>
                        </div><div className="input2">
                            <p>Instagram</p>
                            <input onChange={handleInstagramChange} type="text" placeholder="Instagram Profile"></input>
                        </div>
                        <div className="input2">
                            <p>Address Line</p>
                            <input onChange={handleAddressChange} type="text" placeholder="Address Line"></input>
                        </div>
                    </div>
                </div>
                <button className={loading ? "formSubmit loading" : "formSubmit"} onClick={()=>{handleSubmitClick()}} disabled= {emailError || importantEmpty || name === "" || wallet === "" || email === "" || username === "" ? true : false}>Submit</button>
                {formError && <p style={{color: 'red', fontSize: '0.8vw', fontFamily: 'Manrope', position: 'relative', bottom: '2vw'}} >{ErrorMessage}</p>}
            </div>
        </div>
    )
}


export default AddArtists