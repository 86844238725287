import React, { useEffect, useState } from "react";
import MainSideBar from "../../Components/Sidebar/sidebar";
import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import './admin.css';
import { useNavigate } from 'react-router-dom';



const Admins = (props)=>{

    const [admins, setAdmins] = useState([]);
    // const [empty, setEmpty] = useState(false);
    const [loadStatus, setLoadStatus] = useState(true)
    const [pageSize, setPageSize]  = useState(8)
    const role = localStorage.getItem('role');

    useEffect(()=>{
        props.setCurrentMenu("admins")

        
        fetch("https://creath.tech/api/admins" )
        .then(response => response.json())
        .then((data)=>{
            if(data.data.length > 0){
                setAdmins(data.data)
                setLoadStatus(false)
            }
            else{
                // setEmpty(true)
                setLoadStatus(false)
            }
        })
        .catch((e)=>{
            console.log(e)
        })
    },[])

    let navigate = useNavigate();

    const changePage = (n)=>{
        navigate(n)
    }

    const column = [
        {
            field: 'full_name', headerName: 'Admin Name',width: 200
        },
        {
            field: 'email', headerName: 'Email',width: 200
        },
        {
            field: 'phone', headerName: 'Phone Number',width: 200
        },
        {
            field: 'password', headerName: 'Password', width: 200
        },
        {
            field: 'created_at', headerName: 'Date', type: 'date',width: 200
        }
    ]

    const handleRowClick = (params)=>{
        props.setCurrentAdmin(params.row)
        changePage('/adminProfile')
        localStorage.setItem('currentAdmin', JSON.stringify(params.row))
    }


    return(
        <div className="mainAdmin">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="AdminsSubMain">
                <div className="AdminsHeader">
                    <p>Admins</p>
                    <div>
                        <button disabled = {role !== 'SuperAdmin' ? true : false} onClick={()=>{changePage('/addAdmin')}}>Add Admin</button>
                    </div>
                </div>
                <div className="adminGrid">
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            columns={column}
                            rows={admins}
                            loading={loadStatus}
                            pageSize = {pageSize}
                            onPageSizeChange = {(newPageSize)=>setPageSize(newPageSize)}
                            rowsPerPageOptions = {[8, 16, 24]}
                            autoHeight
                            pagination
                            onRowClick={handleRowClick}
                        />
                    </Box>
                </div>
            </div>
        </div>
    )
}


export default Admins