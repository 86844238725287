import React, {useState} from "react";
import './collectionCard.css'



const CollectionCard = (props)=>{
    const [loading, setLoading] = useState(false)
    const [isAdded, setIsAdded] = useState(false)

    const data = new FormData()
    data.append("exhibition_id", `${props.exhibition_id}`)
    data.append("id", `${props.collection.id}`)

    const handleSubmit = ()=>{
        setLoading(true)

        fetch(`https://creath.tech/api/admin/collections/update`,{
            method: "POST",
            headers:{
                Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            body: data,
        })
        .then(response => response.json())
        .then((data)=>{
            if(data.message){
                setIsAdded(true)
                fetch("https://creath.tech/api/collections")
                .then(response => response.json())
                .then((data)=>{
                  localStorage.setItem('collections',JSON.stringify(data.data))
                })
                .catch((e)=>{
                  console.log(e)
                })
            }
        })
    }
    
    return(
        <div className={"mainCollectionCard"} id={isAdded ? "exclude" : ""}>
            <div className="details">
                <img src={`${props.collection.cover_image}`} alt="Collection cover image"/>
                <div className="textDeets">
                    <p className="name">{props.collection.name}</p>
                    <p className="desc">{props.collection.description}</p>
                </div>
            </div>
            <button onClick={()=>{handleSubmit()}} className={loading ? "loading" : ""}>Add to Exhibition</button>
        </div>
    )
}

export default CollectionCard