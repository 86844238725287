import React, { useEffect, useState } from 'react';
import MainSideBar from '../../Components/Sidebar/sidebar';
import './addArt2.css';
import ArtUpload from '../../Components/ArtUploadForm/artUpload';
import { useNavigate } from 'react-router-dom';

const AddArt2 = (props)=>{

    const [forms, setForms] = useState([])
    const [collection, setCollection] = useState("")
    const [formCount, setFormCount] = useState(1)
    const [artistId, setArtisId] = useState("")
    const [artistWallet, setArtistWallet] = useState("")
    const [artistName, setArtistName] = useState("");
    let navigate = useNavigate();

    
    useEffect(()=>{
        props.setCurrentMenu('artists')
        if(JSON.parse(localStorage.getItem("currentArtist"))){
            const profile = JSON.parse(localStorage.getItem("currentArtist"))
            console.log(profile)
            setArtisId(profile.id);
            setArtistWallet(profile.wallet)
            setArtistName(profile.username)
        }
        else{
            setArtisId("0");
        }
        if(props.currentCollectionAddress === ""){
            let collection = JSON.parse(localStorage.getItem('collection'));
            setCollection(collection);
            console.log(collection)
        }
        else{
            setCollection(props.currentCollectionAddress);
            console.log(props.currentCollectionAddress)
        }
    },[])


    const addMore = ()=>{
        if(formCount === 1){
            setForms([...forms, <ArtUpload artistName = {artistName} artistWallet = {artistWallet} artistId = {artistId} collection={collection} key={0} index = {0} />])
            setFormCount(formCount + 1)
        }
        else{
            setForms([...forms, <ArtUpload artistName = {artistName} artistWallet = {artistWallet} artistId = {artistId} collection={collection} key={formCount - 1} index = {formCount - 1}/>])
            setFormCount(formCount + 1)
        }
    }

    
    const changePage = (n)=>{
        navigate(n)
    }

    const close = ()=>{
        changePage('/artWorks')
    }


    return(
        <div className="mainAddArt1">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="subMainAddArt1">
                <div className="ArtistProfilesHeader">
                    <p>Artist Profile</p>
                </div>
                <div className="ArtistProfileFloW">
                    <p><span>Artist > </span><span> Artist Profile > </span><span>Collection Name > </span> Create Collection</p>
                </div>
                <div className='addArtist2FormHeader'>
                    <p>{collection.name}</p>
                </div>
                <div className='uploadForms'>
                    {forms.map(form => form)}
                </div>
            </div>
            <button className='addMoreButton' onClick = {()=>{addMore()}}>Add Art</button>
            <button className='closeButton' onClick = {()=>{close()}}>Done</button>
        </div>
    )
}

export default AddArt2;