import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import MainSideBar from "../../Components/Sidebar/sidebar";
import './artProfile.css';


const ArtProfile = (props)=>{
    const [art, setArt] = useState({})
    const [artist, setArtist] = useState({})
    const [categories, setCategories] = useState([])
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const id = searchParams.get("id");

    useEffect(()=>{
        console.log(id)
        props.setCurrentMenu('artworks')
        fetch(`https://creath.tech/api/arts/${id}`)
        .then(response => response.json())
        .then((data) =>{
            setArt(data.data)
            if(data.data){
                fetch(`https://creath.tech/api/users/${data.data.artist_id}`)
                .then(response=>response.json())
                .then((data)=>{
                    setArtist(data.data)
                })
            }
        } )
    },[])

    useEffect(()=>{
        fetch('https://creath.tech/api/categories')
        .then(response => response.json())
        .then((data)=>{
            setCategories(data.data)
        })
    },[])


    const deleteArt = ()=>{
        setLoading(true)
        fetch(`https://creath.tech/api/arts/${id}`,{
            method: "DELETE",
            headers:{
                Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            }
        })
        .then(response => response.json())
        .then((data)=>{
            console.log(data)
        })
    }



    return(
        <div className="mainArtProfile">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="subMainArtProfile">
                <div className="AddAdminsProfilesHeader">
                    <p>Artworks</p>
                </div>
                <div className="AddAdminsProfileFloW">
                    <p><span>Artworks > </span>Art Profile</p>
                </div>
                <div className="artDetails">
                    <img src={`${art.art_image}`} alt={`${art.name}`}/>
                    <div className="artDetailsText">
                        <p className="artName">{art.name}</p>
                        <p className="artArtist"><span>Created by : </span> {artist.full_name}</p>
                        <p className="artPrice">{art.price} USDC</p>
                        <button onClick={()=>{deleteArt()}} className={loading? "loading" : ""}>Delete Artwork</button>
                    </div>
                </div>
                <div className="extraDetails">
                    <div className="detailInputs">
                        <p>Dimension</p>
                        <input defaultValue={art.dimension}></input>
                    </div>
                    <div className="detailInputs">
                        <p>Weight</p>
                        <input defaultValue={art.weight}></input>
                    </div>
                    <div className="detailInputs">
                        <p>Medium</p>
                        <input defaultValue={categories.map((el,i)=>{
                            if(categories[i].id === parseInt(art.category_id)){
                                return categories[i].name
                            }
                        })}
                        ></input>
                    </div>
                    <div className="detailInputs">
                        <p>Location of artist</p>
                        <input defaultValue={art.artist_location}></input>
                    </div>
                    <div className="detailInputs">
                        <p>Description</p>
                        <input defaultValue={art.description}></input>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArtProfile;