import React, {useState} from "react";
import './login.css';
import { useNavigate } from 'react-router-dom';




const Login = (props)=>{

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [loading, setLoading] = useState(false)

    let navigate = useNavigate();
    var data = new FormData()

    data.append("email", email)
    data.append("password", password)

    const changePage = ()=>{
        navigate('/dashboard')
    }

    const handleEmailChange = (e)=>{
        setEmail(e.target.value)
    }

    const handlePasswordChange = (e)=>{
        setPassword(e.target.value)
    }

    const handleLogin = ()=>{
        setLoading(true);
        fetch('https://creath.tech/api/admin/login', {
            method : 'POST',
            body : data
        }).then(response => response.json())
        .then((data)=>{
            if(data.message){
                if(data.message === 'Login credentials are invalid.'){
                    setEmailError(true);
                    setLoading(false)
                }
            }
            else{
                props.setCurrentUser(data.data);
                localStorage.setItem("currentUser",JSON.stringify(data.data))
                setLoading(false)
                props.setAccessToken(data.access_token)
                localStorage.setItem('role', data.data.role)
                localStorage.setItem('token',data.access_token)
                props.setIsLoggedIn(true)
                localStorage.setItem("login","true")
                changePage()
            }
        })
        .catch((e)=>{
            setLoading(false)
            console.log(e)
        })
    }


    return(
        <div className="loginMain">
            <h1>Welcome Back!</h1>
            <p>Fill in the details below to get access into your account.</p>
            <div className="loginForm">
                <div>
                    <p>Email Address</p>
                    <input type="email" onChange={handleEmailChange} placeholder="Enter your email"></input>
                </div>
                <div>
                    <p>Password</p>
                    <input type="password" onChange={handlePasswordChange} placeholder="Enter your password"></input>
                </div>
                <button className={loading ? "loadingLogin" : ""} onClick={()=>{handleLogin()}}>Log In</button>
                { emailError ? <p style={{color:'red', fontSize:'1vw', fontFamily:'Manrope', marginTop:'2vw'}}>Please check your login credentials</p> : '' }
            </div>
        </div>
    )
}


export default Login;