import React,{useState, useEffect} from "react";
import { ethers } from 'ethers';
import Select from 'react-select';
import defaultImage from './default_Image.svg'
import Arrow from './up_Arrow.svg'
import ABI from './contractABI.json'
import CreathABI from './CreathABI.json';
import { pinJSONToIPFS } from "../../Util/pinhata";
import './artUpload.css'


const ArtUpload = (props)=>{
    const [ArtName, setArtName] = useState("")
    const [Description, setDescription] = useState("")
    const [physicalCopy, setPhysicalCopy] = useState("unavailable")
    const [location, setLocation] = useState("")
    const [weight, setWeight] = useState("")
    const [price, setPrice] = useState("")
    const [dimension, setDimension] = useState("")
    const [medium, setMedium] = useState("")
    const [categories, setCategories] = useState([])
    const [Art, setArt] = useState(null);
    const [importantEmpty, setImportantEmpty] = useState(true)
    const [loading, setLoading] = useState(false)
    const [formState, setFormState] = useState("collapse")
    const [remove, setRemove] = useState(false);
    const [Contract, setContract] = useState({});
    const [suucessfull, setSuccessful] = useState(false)
    const [imageLink, setImageLink] = useState("")
    const PROVIDER = "https://rpc.sepolia.org"
    const provider = new ethers.providers.JsonRpcProvider(PROVIDER);
    // const collectionContractAddress = '0x0D0b93F79C80A2e2940ba1F281889Be64904D1F0';
    const collectionPrivateKey = "6c6f7eb5ee2662515a6e5d4a576178c028d49b469bbef5e2cd36f6dc84b6f5bd";
    const CollectionWallet = new ethers.Wallet(collectionPrivateKey, provider)
    // const Contract = new ethers.Contract(collectionContractAddress, ABI, CollectionWallet )
    const creathAddress = '0xfE16460C1c2600FBCf7E37878c1877bEB9975b37'
    const ListingContract = new ethers.Contract(creathAddress, CreathABI, CollectionWallet)


    useEffect(()=>{
        let Address = props.collection.nft_address;
        console.log(props.artistName)
        let contract = new ethers.Contract(Address, ABI, CollectionWallet);
        setContract(contract)
        fetch('https://creath.tech/api/categories')
        .then(response => response.json())
        .then((data)=>{
            setCategories(data.data)
        })
    },[])

    const options1 = [
        {value : "0", label:"Unavailable"},
        {value : "1", label:"Available"}
    ]

    const options2 = categories.map((el,i)=>{return {value : `${el.id}`,label:`${el.name}`}})



    const data = new FormData;
    data.append('name', ArtName);
    data.append("description", Description)
    data.append("has_physical_copy", parseInt(physicalCopy))
    data.append("artist_location", location)
    data.append("dimension", dimension)
    data.append("weight",weight)
    data.append("art_image", imageLink)
    data.append("price", price)
    data.append("artist_id", props.artistId)
    data.append("artist_name", props.artistName)

    const pinData = new FormData;
    pinData.append('file', Art)

    const handleArtNameChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true)
        }
        else{
            setArtName(e.target.value)
        }
    }

    const fileHandler = (e) => {
        setArt(e.target.files[0])
    }


    const handleDescriptionChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true);
        }
        else{
            setDescription(e.target.value)
        }
    }

    const handlePhysical = (option)=>{
        setPhysicalCopy(option.value)
    }

    const handleLocationChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true)
        }
        else{
            setLocation(e.target.value)
        }
    }

    const handlePriceChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true)
        }
        else{
            setPrice(e.target.value)
        }
    }

    const handleLinkChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true)
        }
        else{
            setImageLink(e.target.value)
        }
    }

    const handleWeightChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true)
        }
        setWeight(e.target.value)
    }

    const handleDimensionChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true)
        }
        setDimension(e.target.value)
    }

    const handleMediumChange = (option)=>{
        setMedium(option.value)
    } 

    const handleSingleSubmit = async ()=>{
        setLoading(true);
        const pinhataResponse = await pinJSONToIPFS(pinData)
        const tokenURI = pinhataResponse.pinataUrl;
        

        let Txn = await Contract.mint(CollectionWallet.address, tokenURI)
        
        let receipt = await Txn.wait() 

        console.log(receipt)

        let nft_id = parseInt(receipt.events[0].args[2]._hex, 16)
        
        let UnitPrice = ethers.utils.parseUnits(price)

        let Txn2 = await ListingContract.listItem(props.collection.nft_address, props.artistWallet, nft_id, UnitPrice )

        let answer = await Txn2.wait()

        console.log(answer);

        data.append('nft_id',`${parseInt(receipt.events[0].args[2]._hex, 16)}`);

       fetch( `https://creath.tech/api/admin/collections/${medium}/${props.collection.id}/art`,{
            method: "POST",
            body: data,
            headers:{
                'Authorization':`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
       }).then(response=> response.json())
       .then((data)=>{
           if(data.message === "Art created Successfully!"){
               setLoading(false)
               setSuccessful(true)
               setTimeout(()=>{
                setRemove(true)
               },3000)
           }
           let notData = new FormData
            notData.append('name',`${JSON.parse(localStorage.getItem("currentUser")).full_name}`)
            notData.append('email',`${ JSON.parse(localStorage.getItem("currentUser")).email}`)
            notData.append('action', "Created an Artwork")
            fetch('https://creath.tech/api/notifications',{
                method: "POST",
                headers:{
                    Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
                },
                body: notData
            }).then(response => response.json())
       })
       .catch((e)=>{
           setLoading(false)
       })

    }

    const handleStaeChange = ()=>{
        if(formState === "expand"){
            setFormState("collapse")
        }
        else{
            setFormState("expand")
        }
    }

    const removeMe = ()=>{
        setRemove(true);
    }

    return(
        <div className={formState === "expand" ? "mainArtUpload openForm" : "mainArtUpload"} id={remove ? "remove" : ""}>
                <div className="artUploadFormHeader" onClick={()=>{handleStaeChange()}}>
                <div className="artInfo">
                    <p>Art Name</p>
                </div>
                <div className="formStateContainer">
                    <p>{formState === "expand" ? "Collapse" : "Expand"}</p>
                    <img src={Arrow} alt="arrow" className={formState === "expand" ? "" : "turn"}/>
                    <p className="cancel" onClick={()=>{removeMe()}}>X</p>
                </div>
            </div>
            {
                !suucessfull ? 
                <>
            <div className="addSingleForms">
                <div className="addSingleForm1">
                    <div className="input">
                        <p>Art Name**</p>
                        <input type="text" placeholder="Art Name" onChange={handleArtNameChange}/>
                    </div>
                    <div className="input">
                        <p>Art Description**</p>
                        <input type="text" placeholder="Art Description" onChange={handleDescriptionChange}/>
                    </div>
                    <div className="input">
                        <p>Art Image**</p>
                        <input type="text" placeholder="Art Image Link" onChange={handleLinkChange}/>
                    </div>
                    <div className="input">
                        <p>Physical Copy Availability**</p>
                        <Select
                            options={options1}
                            placeholder="Unavailable"
                            onChange={handlePhysical}
                        />
                    </div>
                    <div className="input">
                        <p>Medium**</p>
                        <Select
                            options={options2}
                            placeholder="Select Medium"
                            onChange={handleMediumChange}
                        />
                    </div>
                    <div className="input">
                        <p>Dimension (CM)**</p>
                        <input type="text" placeholder="Art Dimension" onChange={handleDimensionChange}/>
                    </div>
                    <div className="input">
                        <p>Weight (KG)**</p>
                        <input type="text" placeholder="Art Weight" onChange={handleWeightChange}/>
                    </div>
                    <div className="input">
                        <p>Price (USDC)**</p>
                        <input type="number" placeholder="Art Price" onChange={handlePriceChange}/>
                    </div>
                    <div className="input">
                        <p>Location of Artist**</p>
                        <input type="text" placeholder="Artist Location" onChange={handleLocationChange}/>
                    </div>
                </div>
                <div className="addSingleForm2">
                    {
                        Art ?
                            Art.type.includes("mp4") || Art.type.includes("mkv") ?
                                <video className="artMedia"  autoPlay={true} muted={true} loop>
                                    <source src={URL.createObjectURL(Art)} />
                                </video>
                            :
                                <img className="artMedia"  src={URL.createObjectURL(Art)} alt="default Avatar" />
                        :
                        <img className="artMedia"  src={defaultImage} alt="default"/>
                    }
                    <input type="file" placeholder="Choose Art" accept=".jpg, .jpeg, .png, .gif, .mp4, .mkv" onChange={fileHandler} name="file_upload"></input>
                    <p className="file_label">Add Art</p>
                </div>
                <button disabled = {Art === null || ArtName === "" || Description === "" || physicalCopy === "" || location === ""  || weight === "" || price === "" || dimension === "" ||  medium === "" ? true : false} onClick={()=>{handleSingleSubmit()}} className={loading? "loading" : ""} >Upload Artwork</button>
            </div>
                </>

                :

                <>
                    <div className="successFul">
                        <h1>Art addedd to collection successfully</h1>
                    </div>
                </>
            }
        </div>
    )
}

export default ArtUpload