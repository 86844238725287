import React, { useEffect, useState } from 'react'
import MainSideBar from '../../Components/Sidebar/sidebar'
import { useSearchParams } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { useNavigate } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './collectionProfile.css'


const CollectionProfile = (props)=>{
    const [searchParams] = useSearchParams();
    const [collection, setCollection] = useState({})
    const [artist, setArtist] = useState({})
    const [exhibition, setExhibition] = useState({})
    const [arts, setArts] = useState([])
    const id = searchParams.get("id");


    useEffect(()=>{
        props.setCurrentMenu("artworks")
        let placeArts = JSON.parse(localStorage.getItem("arts"))
        let Arts = []
        fetch(`https://creath.tech/api/collections/${id}`)
        .then(response => response.json())
        .then((data) =>{
            localStorage.setItem('collection', JSON.stringify(data.data));
            props.setCollectionAddress(data.data);
            for(var i = 0; i < placeArts.length; i++){
                if(placeArts[i]){
                    if(placeArts[i].collection_id === data.data.id.toString()){
                        Arts.push(placeArts[i])
                    }
                }
            }
            setArts(Arts)
            setCollection(data.data)
            if(data.data.user_id){
                fetch(`https://creath.tech/api/users/${data.data.user_id}`)
                .then(response => response.json())
                .then((data)=>{
                    setArtist(data.data)
                })
            }
            if(data.data.exhibition_id){
                fetch(`https://creath.tech/api/exhibitions/${data.data.exhibition_id}`)
                .then(response => response.json())
                .then((data) =>{
                    setExhibition(data.data)
                })
            }
        })
    },[])

    let navigate = useNavigate();

    const changePage = (n)=>{
        navigate(n)
    }

    const openArt = (id)=>{
        changePage(`/artProfile?id=${id}`)
    }

    const openExhibition = ()=>{
        changePage(`/exhibitionsProfile?id=${exhibition.id}`)
    }

    const addArt = ()=>{
        changePage("/addArt2")
        localStorage.setItem("currentArtist",JSON.stringify(artist))
    }

    return(
        <div className='mainCollectionProfile'>
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu} />
            <div className='subMainCollectionProfile'>
                <div className='collectionTextDetails'>
                    <div className='textDetails'>
                        <div className='deet'>
                            <p>Name : {collection.name}</p>
                        </div>
                        <div className='deet'>
                            <p>Description : {collection.description}</p>
                        </div>
                        <div className='deet'>
                            {
                                collection.exhibition_id ?
                                <p>Exhibition Name: {exhibition.name}</p>
                                :
                                <p>Artist Name : {artist.full_name}</p>
                            }
                        </div>
                        <button disabled={collection.exhibition_id ? false : true} onClick={()=>{openExhibition()}}>View Collection Exhibition</button>
                    </div>
                    <img src={`${collection.cover_image}`} alt="Collection cover"/>
                </div>
                <div className='workSlides'>
                    <p className='slideHeader'>Collection Arts</p>
                    <CarouselProvider
                        naturalSlideWidth={100}
                        naturalSlideHeight={125}
                        totalSlides={arts.length}
                        visibleSlides={3}
                    >
                        <Slider>
                            {
                                arts.map((el,i)=>{
                                    return(
                                        <Slide key={i} index={i}>
                                            <div  className="keen-slider__slide" key={i} onClick={()=>{openArt(arts[i].id)}}>
                                                <img src={`${arts[i].art_image}`} alt={`${arts[i].name}`}/>
                                                <p>{arts[i].name}</p>
                                                <p>{artist.full_name}</p>
                                                <div className="artprice">
                                                    <p>{arts[i].price} USDT</p>
                                                </div>
                                            </div>
                                        </Slide>
                                    )
                                })
                            }
                        </Slider>
                        <ButtonBack>{'<'}</ButtonBack>
                        <ButtonNext>{'>'}</ButtonNext>
                    </CarouselProvider>
                </div>
                <button className='addtoCollect' onClick={()=>{addArt()}}>Add Artworks</button>
            </div>
        </div>
    )
}

export default CollectionProfile