import React, {useState,useEffect} from "react";
import MainSideBar from "../../Components/Sidebar/sidebar";
import { useNavigate } from 'react-router-dom';
import './adminProfile.css';
// import defaultImage from './default_Image.svg's

const AdminProfile = (props)=>{
    const [profileImage, setProfileImage]  = useState(null);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [formError, setFormError] = useState(false)
    const [ErrorMessage, setErrorMessage] = useState("Something went wrong, please try again");
    const [user, setUser] = useState("");
    const role = localStorage.getItem('role');




    useEffect(()=>{
        props.setCurrentMenu("admins")

        let adminProfile = JSON.parse(localStorage.getItem('currentAdmin'));
        if((Object.keys(props.currentAdmin).length === 0)){
            props.setCurrentAdmin(adminProfile)
            setName(adminProfile.full_name);
            setEmail(adminProfile.email);
            setProfileImage(adminProfile.profile_image);
            setPhone(adminProfile.phone);
        }
        else{
            setName(props.currentAdmin.full_name);
            setEmail(props.currentAdmin.email);
            setProfileImage(props.currentAdmin.profile_image);
            setPhone(props.currentAdmin.phone);
        }
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if(Object.keys(props.currentUser).length === 0){
            setUser(currentUser)
            setUser(user)
        }
        else{
            setUser(currentUser)
        }
    },[])



    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    let navigate = useNavigate();

    const changePage = (n)=>{
        navigate(n)
    }


    var data = new FormData()
    if(name !== props.currentAdmin.name){
        data.append("full_name", name)
    }
    if(email !== props.currentAdmin.email){
        data.append("email", email)
    }
    if(phone !== props.currentAdmin.phone){
        data.append("phone",phone)
    }

    const handleSubmitClick = ()=>{
        setLoading(true)
        
        fetch(`https://creath.tech/api/admins/${JSON.parse(localStorage.getItem('currentAdmin')).id}`, {
            method: "POST",
            headers:{
                Authorization:`Bearer${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            body: data
        })
        .then((response) => {return response.json()})
        .then((data)=>{
            if(data.message){
                setLoading(false)
                changePage("/admins")
                let notData = new FormData()
                notData.append('name',`${JSON.parse(localStorage.getItem("currentUser")).full_name}`)
                notData.append('email',`${ JSON.parse(localStorage.getItem("currentUser")).email}`)
                notData.append('action', "Updated an Admin")
                fetch('https://creath.tech/api/notifications',{
                    method: "POST",
                    headers:{
                        Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
                    },
                    body: notData
                }).then(response => response.json())
                .then((data)=>{console.log(data)})
            }
            else if(data.error){
                setFormError(true)
                setLoading(false)
                setErrorMessage(data.error.email[0])
            }
        })
        .catch((e)=>{
            console.log(e)
            setLoading(false)
            setFormError(true)
        })
    }

    const handleEmailChange = (e)=>{
        if(isValidEmail(e.target.value)){
            setEmail(e.target.value)
            setEmailError(false)
        }
        else if(e.target.value === ""){
            setEmail(props.currentAdmin.email)
            setEmailError(false)
        }
        else{
            setEmailError(true)
        }
    }

    const handleNameChange = (e)=>{
        if(e.target.value === ""){
            setName(props.currentAdmin.name)
        }
        else{
            setName(e.target.value);
        }
    }

    const handlePhoneChange = (e)=>{
        if(e.target.value = ""){
            setName(props.currentAdmin.phone)
        }
        else{
            setPhone(e.target.value)
        }
    }

    const handleDeleteClick = ()=>{
        setLoading2(true);
        fetch(`https://creath.tech/api/admin/users/${props.currentAdmin.id}`, {
            headers:{
                Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            method: "DELETE"
        })
        .then(response =>  response.json())
        .then((data)=>{
            console.log(data)
            setLoading2(false)
            changePage('/admins')
            let notData = new FormData()
                notData.append('name',`${JSON.parse(localStorage.getItem("currentUser")).full_name}`)
                notData.append('email',`${ JSON.parse(localStorage.getItem("currentUser")).email}`)
                notData.append('action', "Deleted an Admin")
                fetch('https://creath.tech/api/notifications',{
                    method: "POST",
                    headers:{
                        Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
                    },
                    body: notData
                }).then(response => response.json())
                .then((data)=>{console.log(data)})
        })
        .catch((e)=>{
            setLoading2(false);
            console.log(e)
        })
    }

    return(
        <div className="mainAddAdminsProfiles">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="AddAdminsProfilesSubMain">
                <div className="AddAdminsProfilesHeader">
                    <p>Admin</p>
                </div>
                <div className="AddAdminsProfileFloW">
                    <p><span>Admin > </span>Admin Profile</p>
                </div>
                <div className="AddAdminsProfileForm">
                    <div className="Form1">
                        <div className="input1">
                            <p>Full Name*</p>
                            <input disabled = {role !== 'SuperAdmin' ? true : false} onChange={handleNameChange} type="text" placeholder={name}></input>
                        </div>
                        <div className="input1">
                            <p>Email*</p>
                            <input disabled = {role !== 'SuperAdmin' ? true : false} onChange={handleEmailChange} type="email" placeholder={email}></input>
                            {emailError && <p style={{color: 'red', fontSize: '0.8vw', fontFamily: 'Manrope'}} >Enter A valid email</p>}
                        </div>
                        <div className="input1">
                            <p>Phone Number</p>
                            <input disabled = {role !== 'SuperAdmin' ? true : false}  onChange={handlePhoneChange} type="text" placeholder={phone}></input>
                        </div>
                    </div>
                    <div className="Form2">
                        <img src={`https://creath.tech/storage${profileImage}`} alt="default Avatar"/>
                    </div>
                </div>
                <div className="adminProfileHandlingButtons">
                    <div>
                        <button className={loading ? "formSubmit loading" : "formSubmit"} onClick={()=>{handleSubmitClick()}} disabled= {emailError || role !== 'SuperAdmin' ? true : false}>Update Admin</button>
                        {formError && <p style={{color: 'red', fontSize: '0.8vw', fontFamily: 'Manrope', position: 'relative', bottom: '2vw'}} >{ErrorMessage}</p>}
                    </ div>
                    <button disabled = {role !== 'SuperAdmin' ? true : false} className={ loading2 ? "loading2" : ""} onClick={()=>{handleDeleteClick()}}>Delete Admin</button>
                </div>
            </div>
        </div>
    )
}

export default AdminProfile;