import React, {useState, useEffect} from "react";
import MainSideBar from "../../Components/Sidebar/sidebar";
import { useNavigate } from 'react-router-dom';
import './settings.css';
// import defaultImage from './default_Image.svg'


const Settings = (props)=>{
    const [profileImage, setProfileImage]  = useState(null);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState(false)
    const [ErrorMessage, setErrorMessage] = useState("Something went wrong, please try again");
    const fileHandler = (e) => {
        setProfileImage(e.target.files[0])
    }



    useEffect(()=>{
        props.setCurrentMenu("settings")
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if(Object.keys(props.currentUser).length === 0){
            props.setCurrentUser(currentUser)
            fetch(`https://creath.tech/api/users/${currentUser.id}`)
            .then(response => response.json())
            .then((data)=>{
                setName(data.data.full_name)
                setEmail(data.data.email)
                setPhone(data.data.phone)
                setProfileImage(data.data.profile_image)
            })
        }
        else{
            fetch(`https://creath.tech/api/users/${props.currentUser.id}`)
            .then(response => response.json())
            .then((data)=>{
                setName(data.data.full_name)
                setEmail(data.data.email)
                setPhone(data.data.phone)
                setProfileImage(data.data.profile_image)
            })
        }

    },[])

    const removeProfileImage = ()=>{
        setProfileImage(null)
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    let navigate = useNavigate();

    const changePage = (n)=>{
        navigate(n)
    }



    const handleLogout = ()=>{
        localStorage.setItem('login', "false")
        props.setIsLoggedIn(false)
        changePage("/")
        fetch(`https://creath.tech/api/admin/logout`,{
            method: "POST",
            headers:{
                Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            }
        }).then(response => response.json())
        .then((data)=>{
            console.log(data)
        })
    }

    var data = new FormData()
    if(name !== props.currentUser.name){
        data.append("full_name", name)
    }
    if(email !== props.currentUser.email){
        data.append("email", email)
    }
    if(phone !== props.currentUser.phone){
        data.append("phone",phone)
    }
    if(profileImage !== props.currentUser.profile_image){
        data.append("profile_image", profileImage)
    }
   


    const handleSubmitClick = ()=>{
        setLoading(true)
        
        fetch(`https://creath.tech/api/admin/users/${props.currentUser.id}`, {
            headers:{
                Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            method: "POST",
            body: data
        })
        .then((response) => {return response.json()})
        .then((data)=>{
            if(data.message){
                setLoading(false)
                changePage("/admins")
            }
            else if(data.error){
                setFormError(true)
                setLoading(false)
                setErrorMessage(data.error.email[0])
            }
        })
        .catch((e)=>{
            console.log(e)
            setLoading(false)
            setFormError(true)
        })
    }

    const handleEmailChange = (e)=>{
        if(isValidEmail(e.target.value)){
            setEmail(e.target.value)
            setEmailError(false)
        }
        else if(e.target.value === ""){
            setEmailError(false)
            setEmail(props.currentUser.email)
        }
        else{
            setEmailError(true)
        }
    }

    const handleNameChange = (e)=>{
        if(e.target.value === ""){
            setName(props.currentUser.full_name)
        }
        else{
            setName(e.target.value);
        }
    }

    const handlePhoneChange = (e)=>{
        if(e.target.value === ""){
            setPhone(props.currentUser.phone)
        }
        else{
            setPhone(e.target.value )
        }
    }

    return(
        <div className="mainAddAdmins mainSettings">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="AddAdminsSubMain">
                <div className="AddAdminsHeader">
                    <p>Settings</p>
                    <button className="logoutButton" onClick={()=>{handleLogout()}}>Log Out</button>
                </div>
                <div className="AddAdminFloW">
                    <p><span>Admin > </span>Profile</p>
                </div>
                <div className="AddAdminForm">
                    <div className="Form1">
                        <div className="input1">
                            <p>Full Name*</p>
                            <input onChange={handleNameChange} type="text" placeholder={name}></input>
                        </div>
                        <div className="input1">
                            <p>Email*</p>
                            <input onChange={handleEmailChange} type="email" placeholder={email}></input>
                            {emailError && <p style={{color: 'red', fontSize: '0.8vw', fontFamily: 'Manrope'}} >Enter A valid email</p>}
                        </div>
                        <div className="input1">
                            <p>Phone Number</p>
                            <input onChange={handlePhoneChange} type="text" placeholder={phone}></input>
                        </div>
                    </div>
                    <div className="Form2">
                        <img src={profileImage} alt="default Avatar"/>
                        <div className="form2Uploads">
                            <input type="file" placeholder="Upload Image" accept="image/*" onChange={fileHandler} name="file_upload"></input>
                            <button disabled = {!profileImage ? true : false} onClick={()=>{removeProfileImage()}}>Remove Image</button>
                        </div>
                    </div>
                </div>
                <button className={loading ? "formSubmit loading" : "formSubmit"} onClick={()=>{handleSubmitClick()}} disabled= {!emailError ? false : true}>Update Information</button>
                {formError && <p style={{color: 'red', fontSize: '0.8vw', fontFamily: 'Manrope', position: 'relative', bottom: '2vw'}} >{ErrorMessage}</p>}
            </div>
        </div>
    )
}

export default Settings