import React, {useState, useEffect} from "react";
import './addAdmin.css';
import MainSideBar from "../../Components/Sidebar/sidebar";
import { useNavigate } from 'react-router-dom';
import defaultImage from './default_Image.svg'




const AddAdmin = (props)=>{
    const [profileImage, setProfileImage]  = useState(null);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [importantEmpty, setImportantEmpty] = useState(true)
    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState(false)
    const [user, setUser] = useState("");
    const [ErrorMessage, setErrorMessage] = useState("Something went wrong, please try again");
    const fileHandler = (e) => {
        setProfileImage(e.target.value)
    }



    useEffect(()=>{
        props.setCurrentMenu("admins")
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if(Object.keys(props.currentUser).length === 0){
            setUser(currentUser)
        }
        else{
            setUser(currentUser)
        }
    },[])


    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    let navigate = useNavigate();

    const changePage = (n)=>{
        navigate(n)
    }


    var data = new FormData()
    data.append("full_name", name)
    data.append("email", email)
    data.append("role","admin")
    data.append("password",password)
    data.append("phone",phone)
    data.append("profile_image", profileImage)


    const handleSubmitClick = ()=>{
        setLoading(true)
        
        fetch("https://creath.tech/api/users", {
            method: "POST",
            headers:{
                Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            body: data
        })
        .then((response) => {return response.json()})
        .then((data)=>{
            if(data.message){
                setLoading(false)
                changePage("/admins")
                let notData = new FormData
                notData.append('name',`${JSON.parse(localStorage.getItem("currentUser")).full_name}`)
                notData.append('email',`${ JSON.parse(localStorage.getItem("currentUser")).email}`)
                notData.append('action', "Added an Admin")
                fetch('https://creath.tech/api/notifications',{
                    method: "POST",
                    headers:{
                        Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
                    },
                    body: notData
                }).then(response => response.json())
                .then((data)=>{console.log(data)})
            }
            else if(data.error){
                setFormError(true)
                setLoading(false)
                setErrorMessage(data.error.email[0])
            }
        })
        .catch((e)=>{
            console.log(e)
            setLoading(false)
            setFormError(true)
        })
    }

    const handleEmailChange = (e)=>{
        if(isValidEmail(e.target.value)){
            setEmail(e.target.value)
            setEmailError(false)
            setImportantEmpty(false)
        }
        else if(e.target.value === ""){
            setEmailError(false)
            setImportantEmpty(true)
        }
        else{
            setEmailError(true)
        }
    }

    const handleNameChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true);
        }
        else{
            setName(e.target.value);
        }
    }

    const handlePhoneChange = (e)=>{
        setPhone(e.target.value )
    }

    const handlePasswordChange = (e)=>{
        setPassword(e.target.value)
    }

    return(
        <div className="mainAddAdmins">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="AddAdminsSubMain">
                <div className="AddAdminsHeader">
                    <p>Add Admin</p>
                </div>
                <div className="AddAdminFloW">
                    <p><span>Admin > </span>Add Admin</p>
                </div>
                <div className="AddAdminForm">
                    <div className="Form1">
                        <div className="input1">
                            <p>Full Name*</p>
                            <input onChange={handleNameChange} type="text" placeholder="Enter your full name"></input>
                        </div>
                        <div className="input1">
                            <p>Email*</p>
                            <input onChange={handleEmailChange} type="email" placeholder="Enter your email"></input>
                            {emailError && <p style={{color: 'red', fontSize: '0.8vw', fontFamily: 'Manrope'}} >Enter A valid email</p>}
                        </div>
                        <div className="input1">
                            <p>Phone Number</p>
                            <input onChange={handlePhoneChange} type="text" placeholder="Enter a username"></input>
                        </div>
                        <div className="input1">
                            <p>Password</p>
                            <input onChange={handlePasswordChange} type="password" placeholder="Enter a password"></input>
                        </div>
                    </div>
                    <div className="Form2">
                        <img src={profileImage ? profileImage :defaultImage} alt="default Avatar"/>
                        <div className="input1">
                            <p>Profile Picture Link</p>
                            <input onChange={fileHandler} type="text" placeholder="Enter link to profile image"></input>
                        </div>
                    </div>
                </div>
                <button className={loading ? "formSubmit loading" : "formSubmit"} onClick={()=>{handleSubmitClick()}} disabled= {!emailError && !importantEmpty ? false : true}>Create Admin</button>
                {formError && <p style={{color: 'red', fontSize: '0.8vw', fontFamily: 'Manrope', position: 'relative', bottom: '2vw'}} >{ErrorMessage}</p>}
            </div>
        </div>
    )
}

export default AddAdmin;