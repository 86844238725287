import React, {useEffect, useState} from "react";
import './notification.css';
import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import MainSideBar from "../../Components/Sidebar/sidebar";

const Notifications =  (props)=>{
    const [notifications, setNotifications] = useState([])
    const [flags, setFlags] = useState([])
    const [loadStatus, setLoadStatus] = useState(true)
    const [pageSize, setPageSize]  = useState(8)
    const [view, setView] = useState("notification")

    useEffect(()=>{
        props.setCurrentMenu("notifications")
        fetch('https://creath.tech/api/notifications')
        .then(response => response.json())
        .then((data)=>{
            setNotifications(data.data)
            setLoadStatus(false)
        })
        .catch((e)=>{
            setLoadStatus(false)
        })

        fetch('https://creath.tech/api/flags')
        .then(response => response.json())
        .then((data)=>{
            setFlags(data.data)
        })
    },[])

    const column = [
        {
            field: 'name', headerName: 'Name',width: 200
        },
        {
            field: 'email', headerName: 'Email',width: 200
        },
        {
            field: 'action', headerName: 'Action',width: 200
        },
        {
            field: 'created_at', headerName: 'Date', type: 'date',width: 200
        }
    ]

    const column2 = [
        {
            field: 'id', headerName: 'ID', type: 'number'
        },
        {
            field: 'art_id', headerName: 'Art ID',width: 100
        },
        {
            field: 'user_id', headerName: 'User ID',width: 100
        },
        {
            field: 'created_at', headerName: 'Created At', type: 'date',width: 300
        },
        {
            field: 'updated_at', headerName: 'Updated At', type: 'date',width: 300
        }
    ]

    const handleView = (n)=>{
        setView(n)
    }
    
    const handleRowClick = (params)=>{
        console.log(params.row)
    }

    return(
        <div className="maninNotifications">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="NotificationSubMain">
                <div className="notificationHeader">
                    <p>Notifications</p>
                    <div>
                        <input></input>
                    </div>
                </div>
                <div className="notificationCards">
                    <div onClick={()=>{handleView("flags")}} className="notCard">
                        <p>Flagged Artworks</p>
                    </div>
                    <div onClick={()=>{handleView("notification")}} className="notCard">
                        <p>Notifications</p>
                    </div>
                </div>
                <div className="notificationsGrid">
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            columns={view === "notification" ? column : column2}
                            rows={view === "notification" ? notifications : flags}
                            loading={loadStatus}
                            pageSize = {pageSize}
                            onPageSizeChange = {(newPageSize)=>setPageSize(newPageSize)}
                            rowsPerPageOptions = {[8, 16, 24]}
                            autoHeight
                            pagination
                            onRowClick={handleRowClick}
                        />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Notifications;