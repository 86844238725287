import React, { useEffect, useState } from 'react'
import MainSideBar from '../../Components/Sidebar/sidebar'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import defaultImage from './default_Image.svg'
import CollectionCard from '../../Components/CollectionCard/collectionCard';
import './exhibitionProfile.css'



const ExhibitionProfile = (props)=>{
    const [searchParams] = useSearchParams();
    const [exhibition, setExhibition] = useState({})
    const [collections, setCollections] = useState([])
    const [Uncollections, setUnCollections] = useState([])
    const [newImages, setNewImages] = useState([])
    const [isUpdating, setIsUpdating] = useState(false)
    const [isUpdating2, setIs2Updating] = useState(false)
    const [updated, setUpdated] = useState(false)
    const [images, setImages] = useState([])
    const id = searchParams.get("id");


    useEffect(()=>{
        props.setCurrentMenu("artworks")
        let placeCollections = JSON.parse(localStorage.getItem("collections"))
        let collection = []
        let Uncollection = []
        fetch(`https://creath.tech/api/exhibitions/${id}`)
        .then(response => response.json())
        .then((data) =>{
            setExhibition(data.data)
            setImages(data.data.images.split(','))
            for(var i = 0; i < placeCollections.length; i++){
                if(placeCollections[i].exhibition_id === data.data.id.toString()){
                    collection.push(placeCollections[i])
                }
                else if(placeCollections[i].exhibition_id === null){
                    Uncollection.push(placeCollections[i])
                }
            }
            setUnCollections(Uncollection)
            setCollections(collection)
        })
    },[updated])

    let navigate = useNavigate();


    let data = new FormData();
    data.append("is_featured", "1")
    data.append("id", `${exhibition.id}`)
 

    let data2 = new FormData();
    data2.append("id", `${exhibition.id}`)


    const changePage = (n)=>{
        navigate(n)
    }

    const openCollection = (id)=>{
        changePage(`/collectionsProfile?id=${id}`)
    }

    const handleExhiUpdate = ()=>{
        setIsUpdating(true)
        fetch(`https://creath.tech/api/admin/exhibitions/update`,{
            method: "POST",
            headers:{
                Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            body: data
        })
        .then(response => response.json())
        .then((data)=>{
            console.log(data)
            setIsUpdating(false)
            setUpdated(true)
        })
    }

    const fileHandler = (e)=>{
        setNewImages(e.target.value)
    }

    const handleImageUplaod = ()=>{
        setIs2Updating(true)
        data2.append("images",newImages)
        fetch(`https://creath.tech/api/admin/exhibitions/update`,{
            method: "POST",
            headers:{
                Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            body: data2
        })
        .then(response => response.json())
        .then((data)=>{
            console.log(data)
            setIs2Updating(false)
            setUpdated(true)
        })
    }


    return(
        <div className='mainCollectionProfile'>
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu} />
            <div className='exhibitionsubMain'>
                <div className='exhibitionDetails'>
                    <p>Name : {exhibition.name}</p>
                    <p>Description: {exhibition.description}</p>
                    <p>Type: {exhibition.type}</p>
                    <button className={isUpdating ?  "loading" : ""} onClick={()=>{handleExhiUpdate()}} disabled={exhibition.is_featured === "0" ? false : true}>Done</button>
                </div>
                <div className="addCollectionForm2">
                    <div className="input">
                        <p>Exhibition Image Links**</p>
                        <input type="text" placeholder="Add links to exhibition images and seperate with a coma" onChange={fileHandler}></input>
                    </div>
                </div>
                <button onClick={()=>{handleImageUplaod()}} className={isUpdating2 ? 'imageUpld loading' : 'imageUpld'} disabled={newImages[0] ? false : true}>Upload Images</button>
                <div className='workSlides'>
                    <p className='slideHeader'>Image Gallery</p>
                    {
                        Object.keys(exhibition).length !== 0 ?
                        <CarouselProvider
                        naturalSlideWidth={100}
                        naturalSlideHeight={125}
                        totalSlides={exhibition.images.length}
                        visibleSlides={3}
                        >
                            <Slider>
                                {
                                    exhibition.images.map((el,i)=>{
                                        return(
                                            <Slide key={i} index={i}>
                                                <div  className="keen-slider__slide" key={i}>
                                                    <img src={`${images[i]}`} alt="exhibition image"/>
                                                </div>
                                            </Slide>
                                        )
                                    })
                                }
                            </Slider>
                            <ButtonBack>{'<'}</ButtonBack>
                            <ButtonNext>{'>'}</ButtonNext>
                        </CarouselProvider>
                        :
                        <></>
                    }
                </div>
                <div className='workSlides'>
                    <p className='slideHeader'>Exhibition Collections</p>
                    {
                        collections.length === 0 ?
                        <></>
                        :
                    
                        <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={125}
                            totalSlides={collections.length}
                            visibleSlides={3}
                        >
                            <Slider>
                                {
                                    collections.map((el,i)=>{
                                        return(
                                            <Slide key={i} index={i}>
                                                <div  className="keen-slider__slide" key={i} onClick={()=>{openCollection(collections[i].id)}}>
                                                    <img src={`${collections[i].cover_image}`} alt={`${collections[i].name}`}/>
                                                    <p>{collections[i].name}</p>
                                                </div>
                                            </Slide>
                                        )
                                    })
                                }
                            </Slider>
                            <ButtonBack>{'<'}</ButtonBack>
                            <ButtonNext>{'>'}</ButtonNext>
                        </CarouselProvider>
}
                </div>

                <div className='extraCollections'>
                    <p>Add Collections</p>
                    {
                        Uncollections.map((el,i)=>{
                            return(
                                <CollectionCard key={i} collection = {Uncollections[i]} exhibition_id = {exhibition.id}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ExhibitionProfile