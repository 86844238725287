import React, {useEffect, useState} from "react";
import MainSideBar from "../../Components/Sidebar/sidebar";
import { ethers } from "ethers";
import Select from 'react-select';
import CollectionContractABI from './collectionContractABI.json'
import defaultImage from './default_Image.svg'
import { useNavigate } from 'react-router-dom';
import "./addExhibition.css"



const AddExhibition = (props)=>{
    
    const[exhiName, setExhiName] = useState('');
    const [exhiDesc, setExhiDesc] = useState('');
    const [exhiType, setExhiType] = useState('');
    const [link, setLink] = useState('')
    const [exhiImage, setExhiImage] = useState("")
    const [exhiCover, setExhiCover] = useState('')
    const [loading, setLoading] = useState(false)
    const [collectionCover, setCollectionCover] = useState(null)
    const [collectionName, setCollectionName] = useState("")
    const [collectionDescription, setCollectionDescription] = useState("")
    const [loading2, setLoading2] = useState(false)
    const [exhibition_id, setExhiId] = useState("")
    const [step, setStep] = useState("1");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const collectionContractAddress = '0x85579F6112A4D5516f5d7a992aFd95243Ef9998F'
    const collectionPrivateKey = "6c6f7eb5ee2662515a6e5d4a576178c028d49b469bbef5e2cd36f6dc84b6f5bd"
    const CollectionWallet = new ethers.Wallet(collectionPrivateKey, provider)
    const CollectionContractInstance =  new ethers.Contract(collectionContractAddress, CollectionContractABI, CollectionWallet );
    let navigate = useNavigate();

    const options = [
        {value : "Virtual", label:"Virtual"},
        {value : "Physical", label:"Physical"},
        {value: "Hybrid", label:"Hybrid"}
    ]

    const data = new FormData()
    data.append("name", exhiName);
    data.append("description", exhiDesc);
    data.append("type", exhiType);
    data.append('link', link);
    data.append('images', exhiImage)
    data.append('cover_image', exhiCover)


    const data2 = new FormData();
    data2.append('name', collectionName);
    data2.append('description', collectionDescription)
    data2.append('cover_image', collectionCover)

    useEffect(()=>{
        props.setCurrentMenu("artworks")
    },[])

    const handleExhiType = (option)=>{
        setExhiType(option.value)
    }

    const handleExhiName = (e)=>{
        setExhiName(e.target.value)
    }

    const hanldeExhiDesc = (e)=>{
        setExhiDesc(e.target.value)
    }

    const fileHandler = (e)=>{
        setExhiImage(e.target.value)
    }

    const hanldeExhiCoverLink = (e)=>{
        setExhiCover(e.target.value)
    }

    const handleExhiSubmit = async ()=>{
        setLoading(true);

        fetch(`https://creath.tech/api/admin/exhibitions`, {
            method: "POST",
            headers:{
                'Authorization':`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            body: data
        }).then(response=> response.json())
        .then((data)=>{
            if(data.data.created_at){
                setLoading(false)
                changePage("/artworks")
                // setStep("2")
                setExhiId(`${data.data.id}`)
                let notData = new FormData
                notData.append('name',`${JSON.parse(localStorage.getItem("currentUser")).full_name}`)
                notData.append('email',`${ JSON.parse(localStorage.getItem("currentUser")).email}`)
                notData.append('action', "Added an Exhibition")
                fetch('https://creath.tech/api/notifications',{
                    method: "POST",
                    headers:{
                        Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
                    },
                    body: notData
                }).then(response => response.json())
                .then((data)=>{console.log(data)})
            }
        })
        .catch((e)=>{
            setLoading(false)
        })
    }

    const fileHandler2 = (e) => {
        setCollectionCover(e.target.files[0])
    }

    const handleCollectionNameChange=(e)=>{
        setCollectionName(e.target.value)
    }

    const handleCollectionDescriptionChange = (e)=>{
        setCollectionDescription(e.target.value)
    }

    const hanldeExhiLink = (e)=>{
        setLink(e.target.value)
    }

    const handleCollectionSubmit = async ()=>{
        setLoading2(true);
        let Txn = await CollectionContractInstance.createNFTContract(collectionName, (collectionName[0] + collectionName[1]).toUpperCase)

        const receipt = await Txn.wait()
        data2.append('nft_address', receipt.events[0].address)

        fetch(`https://creath.tech/api/admin/exhibitions/${exhibition_id}/collection`, {
            method: "POST",
            headers:{
                'Authorization':`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            body: data2
        }).then(response=> response.json())
        .then((data)=>{
            if(data.data.created_at){
                setLoading2(false);
                props.setCollectionAddress(data.data)
                localStorage.setItem('collection', JSON.stringify(data.data));
                changePage("/addArt2")
                let notData = new FormData()
                notData.append('name',`${JSON.parse(localStorage.getItem("currentUser")).full_name}`)
                notData.append('email',`${ JSON.parse(localStorage.getItem("currentUser")).email}`)
                notData.append('action', "Added an Exhibition")
                fetch('https://creath.tech/api/notifications',{
                    method: "POST",
                    headers:{
                        Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
                    },
                    body: notData
                }).then(response => response.json())
                .then((data)=>{console.log(data)})
            }

        })
        .catch((e)=>{
            setLoading(false)
        })
    }

    const changePage = (n)=>{
        navigate(n)
    }


    return(
        <div className="mainAddExhibtion">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="subAddExhibition">
            <div className="ArtistProfilesHeader">
                    <p>Artworks</p>
                </div>
                <div className="ArtistProfileFloW">
                    <p><span>Artworks > </span>Add Exhibition</p>
                </div>
                { step === "1" ?
                    <div className="addCollectionFoms">
                        <div className="addCollectionForm1">
                            <div className="input">
                                <p>Exhibition Name**</p>
                                <input type="text" placeholder="Exhibition Name" onChange={handleExhiName}></input>
                            </div>
                            <div className="input">
                                <p>Exhibition Description**</p>
                                <input type="text" placeholder="Exhibition Description" onChange={hanldeExhiDesc}></input>
                            </div>
                            <div className="input">
                                <p>Exhibition Type**</p>
                                <Select
                                    options={options}
                                    placeholder="Virtual"
                                    onChange={handleExhiType}
                                />
                            </div>
                            <div className="input">
                                <p>Exhibition Registration Link**</p>
                                <input type="text" placeholder="Exhibition Link" onChange={hanldeExhiLink}></input>
                            </div>
                            <div className="input">
                                <p>Exhibition Cover**</p>
                                <input type="text" placeholder="Exhibition Cover Image Link" onChange={hanldeExhiCoverLink}></input>
                            </div>
                            <div className="input">
                                <p>Exhibition Image Links**</p>
                                <input type="text" placeholder="Add links to exhibition images and seperate with a coma" onChange={fileHandler}></input>
                            </div>
                        </div>
                        <button disabled = {exhiName === "" || exhiCover === "" || exhiDesc === "" || exhiImage === "" || exhiType==="" || link === "" ? true : false} onClick={()=>{handleExhiSubmit()}} className={loading? "loading" : ""}>Next</button>
                    </div>
                    :
                    <div className="addCollectionFoms">
                        <div className="addCollectionForm1">
                            <div className="input">
                                <p>Collection Name**</p>
                                <input type="text" placeholder="Collection Name" onChange={handleCollectionNameChange}/>
                            </div>
                            <div className="input">
                                <p>Collection Description**</p>
                                <input type="text" placeholder="Collection Description" onChange={handleCollectionDescriptionChange}/>
                            </div>
                        </div>
                        <div className="addCollectionForm2">
                            <img className="artMedia" src={collectionCover ? URL.createObjectURL(collectionCover) :defaultImage} alt="default Avatar"/>
                            <input type="file" placeholder="Choose Art" accept=".jpg, .jpeg, .png, .gif" onChange={fileHandler2} name="file_upload"></input>
                            <p className="file_label">Collection Cover</p>
                        </div>
                        <button onClick={()=>{handleCollectionSubmit()}} className={loading2? "loading" : ""}>Next</button>
                    </div>
                }   
            </div>
        </div>
    )
}

export default AddExhibition;