import React, { useState,useEffect } from "react";
import MainSideBar from "../../Components/Sidebar/sidebar";
import { CountryDropdown, RegionDropdown} from 'react-country-region-selector';
import { useNavigate } from 'react-router-dom';
import './artistProfile.css'
import Select from 'react-select';
import Empty from './empty.png';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
// import { fabClasses } from "@mui/material";



const ArtistProfile = (props)=>{
    const [profiles, setProfile] = useState(JSON.parse(localStorage.getItem("currentArtist")))
    const [country, setCountry] = useState(profiles.country);
    const [state, setState] = useState(profiles.state);
    // const profileImage = profiles.profile_image;
    const [name, setName] = useState(profiles.full_name)
    const [email, setEmail] = useState(profiles.email)
    const [address, setAddress] = useState(profiles.address)
    const [bio, setBio] = useState(profiles.bio);
    const [username, setUserName] = useState(profiles.username)
    const [twitter, setTwitter] = useState(profiles.twitter);
    const [instagram, setInstagram] = useState(profiles.instagram)
    const [wallet, setWallet] = useState(profiles.wallet)
    const [emailError, setEmailError] = useState(false)
    const [importantEmpty, setImportantEmpty] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [personalWorks, setPersonalWorks] = useState([])
    const [collection, setCollections] = useState([])
    const [isEmpty, setIsEmpty] = useState(true)
    const [formError, setFormError] = useState(false)
    const [ErrorMessage, setErrorMessage] = useState("Something went wrong, please try again");
    const [currentRow, setCurrentRow] = useState("NFT")
    const [user, setUser] = useState("");

    useEffect(()=>{
        props.setCurrentMenu("artists")
        const profile = JSON.parse(localStorage.getItem("currentArtist"))
        if(Object.keys(props.currentArtist).length === 0){
            setProfile(profile);
            console.log(profile)
            props.setCurrentArtist(profile)
        }
        else{
            setProfile(props.currentArtist)
        }
        let personal = [];
        fetch("https://creath.tech/api/arts")
        .then(response => response.json())
        .then((data)=>{
            for(var i = 0; i < data.data.length; i++){
                if(data.data[i].artist_id === profile.id.toString()){
                    personal.push(data.data[i])
                }
            }
            setPersonalWorks(personal)
        })
        .catch((e)=>{
        console.log(e)
        })

        let personalCollection = []
        fetch(`https://creath.tech/api/collections`)
        .then(response=>response.json())
        .then((data)=>{
            for(var i = 0; i < data.data.length; i++ ){
                if(data.data[i].user_id === profile.id.toString()){
                    personalCollection.push(data.data[i])
                }
            }
            setCollections(personalCollection)
        })
        .catch((e)=>{
            console.log(e)
        })

        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if(Object.keys(props.currentUser).length === 0){
            setUser(currentUser)
        }
        else{
            setUser(currentUser)
        }

    },[])



    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const options = [
        {value: 'NFT', label: 'Artworks'},
        {value:'collections', label: 'Collections'}
    ]

    let navigate = useNavigate();

    const changePage = (n)=>{
        navigate(n)
    }


    var data = new FormData()
    if(name !== props.currentArtist.name){
        data.append("full_name", name)
    }
    if(email !== props.currentArtist.email){
        data.append("email", email)
    }
    if(address !== props.currentArtist.address){
        data.append("address", address)
    }
    if(bio !== props.currentArtist.bio){
        data.append("bio", bio)
    }
    if(username !==  props.currentArtist.username){
        data.append("username", username)
    }
    if(country !== props.currentArtist.country){
        data.append("country", country)
    }
    if(state !== props.currentArtist.state){
        data.append("state", state)
    }
    if(twitter !== props.currentArtist.twitter){
        data.append("twitter", twitter)
    }
    if(instagram !== props.currentArtist.instagram){
        data.append("instagram", instagram)
    }
    if(wallet !== props.currentArtist.wallet){
        data.append("wallet", wallet)
    }




    const handleSubmitClick = ()=>{
        setLoading(true)
        
        fetch(`https://creath.tech/api/admin/users/${profile.id}`, {
            method: "POST",
            body: data
        })
        .then((response) => {return response.json()})
        .then((data)=>{
            if(data.message){
                setLoading(false)
                changePage("/artists")
                let notData = new FormData
                notData.append('name',`${JSON.parse(localStorage.getItem("currentUser")).full_name}`)
                notData.append('email',`${ JSON.parse(localStorage.getItem("currentUser")).email}`)
                notData.append('action', "Updated an Artist")
                notData.append("token", `${JSON.parse(localStorage.getItem("currentUser")).id}`)
                fetch('https://creath.tech/api/notifications',{
                    method: "POST",
                    headers:{
                        Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
                    },
                    body: notData
                }).then(response => response.json())
                .then((data)=>{console.log(data)})
            }
            else if(data.error){
                setFormError(true)
                setLoading(false)
                setErrorMessage(data.error.email[0])
            }
        })
        .catch((e)=>{
            console.log(e)
            setLoading(false)
            setFormError(true)
        })
    }

    const profile = JSON.parse(localStorage.getItem("currentArtist"))

    const handleDelete = ()=>{
        setDeleteLoading(true);
        fetch(`https://creath.tech/api/admin/users/${profile.id}`, {
            headers:{
                Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            method: "DELETE"
        })
        .then(response =>  response.json())
        .then((data)=>{
            console.log(data)
            setDeleteLoading(false)
            changePage('/artists')
            let notData = new FormData
                notData.append('name',`${JSON.parse(localStorage.getItem("currentUser")).full_name}`)
                notData.append('email',`${ JSON.parse(localStorage.getItem("currentUser")).email}`)
                notData.append('action', "Deleted an Artist")
                fetch('https://creath.tech/api/notifications',{
                    method: "POST",
                    headers:{
                        Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
                    },
                    body: notData
                }).then(response => response.json())
                .then((data)=>{console.log(data)})
        })
        .catch((e)=>{
            setDeleteLoading(false);
            console.log(e)
        })
    }

    const handleEmailChange = (e)=>{
        if(isValidEmail(e.target.value)){
            setEmail(e.target.value)
            setEmailError(false)
            setImportantEmpty(false)
        }
        else if(e.target.value === ""){
            setEmailError(false)
            setEmail(props.currentArtist.email)
        }
        else{
            setEmailError(true)
        }
    }

    const handleNameChange = (e)=>{
        if(e.target.value === ''){
            setName(props.currentArtist.name)
        }
        else{
            setName(e.target.value);
        }
    }

    const handleUserNameChange = (e)=>{
        if(e.target.value === ""){
            setUserName(props.currentArtist.username)
        }
        else{
            setUserName(e.target.value)
        }
    }

    const handleBioChange = (e)=>{
        if(e.target.value === ""){
            setBio(props.currentArtist.bio)
        }
        else{
            setBio(e.target.value)
        }
    }

    const handleAddressChange = (e)=>{
        if(e.target.value === ""){
            setAddress(props.currentArtist.address)
        }
        else{
            setAddress(e.target.value)
        }
    }

    const handleTwitterChange = (e)=>{
        if(e.target.value === ""){
            setTwitter(props.currentArtist.twitter)
        }
        else{
            setTwitter(e.target.value);
        }
    }

    const handleInstagramChange = (e)=>{
        if(e.target.value === ""){
            setInstagram(props.currentArtist.twitter)
        }
        else{
            setInstagram(e.target.value);
        }
    }

    const handleWalletChange = (e)=>{
        if(e.target.value === ""){
            setWallet(props.currentArtist.wallet)
        }
        else{
            setWallet(e.target.value);
        }
    }

    const handleSelectChange = (selectedOption) =>{
        setCurrentRow(selectedOption.value)
    }

    const openArt = (id)=>{
        changePage(`/artProfile?id=${id}`)
    }

    const openCollection= (id)=>{
        changePage(`/collectionsProfile?id=${id}`)
    }

    return(
        <div className="mainArtistProfiles">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="ArtistProfilesSubMain">
                <div className="ArtistProfilesHeader">
                    <p>Artist Profile</p>
                </div>
                <div className="ArtistProfileFloW">
                    <p><span>Artist > </span>Artist Profile</p>
                </div>
                <div className="ArtistProfileForm">
                    <div className="Form1">
                        <div className="input1">
                            <p>Full Name*</p>
                            <input onChange={handleNameChange} type="text"  placeholder={profiles.full_name}></input>
                        </div>
                        <div className="input1">
                            <p>Email*</p>
                            <input onChange={handleEmailChange} type="email"  placeholder={profiles.email}></input>
                            {emailError && <p style={{color: 'red', fontSize: '0.8vw', fontFamily: 'Manrope'}} >Enter A valid email</p>}
                        </div>
                        <div className="input1">
                            <p>Username</p>
                            <input onChange={handleUserNameChange} type="text"  placeholder={profiles.username}></input>
                        </div>
                        <div className="input1">
                            <p>Bio</p>
                            <input onChange={handleBioChange} type="text"  placeholder={profiles.bio}></input>
                        </div>
                        <div className="input1">
                            <p>Wallet</p>
                            <input onChange={handleWalletChange} type="text"  placeholder={profiles.wallet}></input>
                        </div>
                        <div className="input1">
                            <p>Country</p>
                            <CountryDropdown
                                value={profiles.country}
                                onChange={(val) => setCountry(val) }
                            />
                        </div>
                        <div className="input1">
                            <p>State</p>
                            <RegionDropdown
                                blankOptionLabel = "No country selected"
                                value={profiles.state}
                                country={profiles.country}
                                onChange={(val) => setState(val) }
                            />
                        </div>
                    </div>
                    <div className="Form2">
                        <img src={`${profile.profile_image}`} alt="default Avatar"/>
                        <div className="input2">
                            <p>Twitter</p>
                            <input onChange={handleTwitterChange} type="text" placeholder={profiles.twitter}></input>
                        </div><div className="input2">
                            <p>Instagram</p>
                            <input onChange={handleInstagramChange} type="text" placeholder={profiles.instagram}></input>
                        </div>
                        <div className="input2">
                            <p>Address Line</p>
                            <input onChange={handleAddressChange} type="text"  placeholder={profiles.address}></input>
                        </div>
                    </div>
                </div>
                <div className="artistProfileButtons">
                    <div>
                        <button className={loading ? "formSubmit loading" : "formSubmit"} onClick={()=>{handleSubmitClick()}} disabled= {!emailError && !importantEmpty ? false : true}>Save Changes</button>
                        {formError && <p style={{color: 'red', fontSize: '0.8vw', fontFamily: 'Manrope', position: 'relative', bottom: '2vw'}} >{ErrorMessage}</p>}
                    </div>
                    <button onClick={()=>{handleDelete()}} className={deleteLoading ? "deleteLoading" : ""}>
                        Delete Account
                    </button>
                </div>
                <div className="works">
                    <div className="worksHeader">
                        <div className="worksHeaderTexts">
                            <p>Uploaded Artworks</p>
                            <Select
                                placeholder="NFT"
                                onChange={handleSelectChange}
                                options={options}
                            />
                        </div>
                        <button onClick={()=>{changePage('/addArt1')}}>Upload</button>
                    </div>
                    
                    {
                        currentRow === "NFT" ? 
                        <>  
                            {
                                personalWorks.length === 0 ?
                                <>
                                    <img src={Empty} className="image404" alt="404 image"/>
                                </>
                                :
                                <>
                                    <div className="workSlides">
                                        <CarouselProvider
                                            naturalSlideWidth={100}
                                            naturalSlideHeight={125}
                                            totalSlides={personalWorks.length}
                                            visibleSlides={3}
                                        >
                                            <Slider>
                                                {
                                                    personalWorks.map((el,i)=>{
                                                        return(
                                                            <Slide key={i} index={i}>
                                                                <div  className="keen-slider__slide" key={i} onClick={()=>{openArt(personalWorks[i].id)}}>
                                                                    <img src={`${personalWorks[i].art_image}`} alt={`${personalWorks[i].name}`}/>
                                                                    <p>{personalWorks[i].name}</p>
                                                                    <p>{profiles.full_name}</p>
                                                                    <div className="artprice">
                                                                        <p>{personalWorks[i].price} USDT</p>
                                                                    </div>
                                                                </div>
                                                            </Slide>
                                                        )
                                                    })
                                                }
                                            </Slider>
                                            <ButtonBack>{'<'}</ButtonBack>
                                            <ButtonNext>{'>'}</ButtonNext>
                                        </CarouselProvider>
                                    </div>
                                </>
                                
                            }
                        </>
                        :
                        <>
                            {
                                collection.length === 0 ?
                                <>
                                    <img src={Empty} className="image404" alt="404 image"/>
                                </>
                                :
                                <>
                                    <div className="workSlides">
                                        <CarouselProvider
                                            naturalSlideWidth={100}
                                            naturalSlideHeight={125}
                                            totalSlides={collection.length}
                                            visibleSlides={3}
                                        >
                                            <Slider>
                                                {
                                                    collection.map((el,i)=>{
                                                        return(
                                                            <Slide key={i} index={i}>
                                                                <div  className="keen-slider__slide" key={i} onClick={()=>{openCollection(collection[i].id)}}>
                                                                    <img src={`${collection[i].cover_image}`} alt={`${collection[i].name}`}/>
                                                                    <p>{collection[i].name}</p>
                                                                </div>
                                                            </Slide>
                                                        )
                                                    })
                                                }
                                            </Slider>
                                            <ButtonBack>{'<'}</ButtonBack>
                                            <ButtonNext>{'>'}</ButtonNext>
                                        </CarouselProvider>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? "arrow--left" : "arrow--right"
        } ${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    )
}


export default ArtistProfile;