import React, { useEffect, useState } from "react";
import MainSideBar from "../../Components/Sidebar/sidebar";
import { ethers } from 'ethers';
import ContratABI from './contractABI.json'
import CreathABI from './CreathABI.json'
import CollectionContractABI from './collectionContractABI.json'
import Select from 'react-select';
import { pinJSONToIPFS } from "../../Util/pinhata";
import defaultImage from './default_Image.svg'
import { useNavigate } from 'react-router-dom';
import './addArt1.css'

const AddArt1 = (props)=>{

    const [type, setType] = useState('single')
    const [ArtName, setArtName] = useState("")
    const [Description, setDescription] = useState("")
    const [physicalCopy, setPhysicalCopy] = useState("unavailable")
    const [location, setLocation] = useState("")
    const [weight, setWeight] = useState("")
    const [price, setPrice] = useState("")
    const [dimension, setDimension] = useState("")
    const [medium, setMedium] = useState("")
    const [Art, setArt] = useState(null);
    const [collectionCover, setCollectionCover] = useState(null)
    const [collectionName, setCollectionName] = useState("")
    const [collectionDescription, setCollectionDescription] = useState("")
    const [importantEmpty, setImportantEmpty] = useState(true)
    const [importantEmpty2, setImportant2Empty] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [categories, setCategories] = useState([])
    const [artistId, setArtisId] = useState("")
    const [artistWallet, setArtistWallet] = useState("")
    const [artistName, setArtistName] = useState("")
    const [imageLink, setImageLink] = useState("")

    const contractAddress = '0xEE241Ef58821530b30F5c0f493C0c2a5cd3bea2F';
    const collectionContractAddress = '0x9b686214BBD9A6e17AC98A7c125b4821E929E4Ec'
    const creathAddress = '0xfE16460C1c2600FBCf7E37878c1877bEB9975b37'

    const privateKey = "0f5ebe62002ae84192f79fed6760dcc1235a0bc81cd7f559e9e2c561285990ec"
    const collectionPrivateKey = "6c6f7eb5ee2662515a6e5d4a576178c028d49b469bbef5e2cd36f6dc84b6f5bd"
    const PROVIDER = "https://rpc.sepolia.org"

    const provider = new ethers.providers.JsonRpcProvider(PROVIDER);
    const Wallet = new ethers.Wallet(privateKey, provider);
    const CollectionWallet = new ethers.Wallet(collectionPrivateKey, provider)
    const ContractInstance = new ethers.Contract(contractAddress, ContratABI, CollectionWallet);
    const CollectionContractInstance =  new ethers.Contract(collectionContractAddress, CollectionContractABI, CollectionWallet );
    const ListingContract = new ethers.Contract(creathAddress, CreathABI, CollectionWallet );
    let navigate = useNavigate();


    useEffect(()=>{
        props.setCurrentMenu("artists")
        const profile = JSON.parse(localStorage.getItem("currentArtist"))
        console.log(profile)
        setArtistName(profile.username)
        setArtistWallet(profile.wallet);
        setArtisId(profile.id);
        fetch('https://creath.tech/api/categories')
        .then(response => response.json())
        .then((data)=>{
            setCategories(data.data)
        })
    },[])

    const changePage = (n)=>{
        navigate(n)
    }

    const options1 = [
        {value : "0", label:"Unavailable"},
        {value : "1", label:"Available"}
    ]

    const options2 = categories.map((el,i)=>{return {value : `${el.id}`,label:`${el.name}`}})


    const data = new FormData;
    data.append('name', ArtName);
    data.append("description", Description)
    data.append("has_physical_copy", parseInt(physicalCopy))
    data.append("artist_location", location)
    data.append("dimension", dimension)
    data.append("weight",weight)
    data.append("art_image", imageLink)
    data.append("price", price)
    data.append("artist_id", artistId)
    data.append("artist_name", artistName)

    const pinData = new FormData;
    pinData.append('file', Art)


    const data2 = new FormData;
    data2.append('name', collectionName);
    data2.append('description', collectionDescription)
    data2.append('cover_image', collectionCover)
    data2.append("user_id", artistId)
    

    const handleTypeClick = (n)=>{
        setType(n)
    }

    const handleArtNameChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true)
        }
        else{
            setArtName(e.target.value)
        }
    }

    const fileHandler = (e) => {
        if(e.target.files[0]){
            setArt(e.target.files[0])
        }
        else{
            setImportantEmpty(true)
        }
    }

    const fileHandler2 = (e) => {
        setCollectionCover(e.target.value)
    }

    const handleDescriptionChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true);
        }
        else{
            setDescription(e.target.value)
        }
    }

    const handlePhysical = (option)=>{
        setPhysicalCopy(option.value)
    }

    const handleLocationChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true)
        }
        else{
            setLocation(e.target.value)
        }
    }

    const handlePriceChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true)
        }
        else{
            setPrice(e.target.value)
        }
    }

    const handleWeightChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true)
        }
        setWeight(e.target.value)
    }

    const handleDimensionChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true)
        }
        setDimension(e.target.value)
    }

    const handleLinkChange = (e)=>{
        if(e.target.value === ""){
            setImportantEmpty(true)
        }
        setImageLink(e.target.value)
    }

    const handleMediumChange = (option)=>{
        setMedium(option.value)
    } 

    const handleCollectionNameChange=(e)=>{
        if(e.target.value === ""){
            setImportant2Empty(true);
        }
        else{
            setCollectionName(e.target.value)
        }
    }

    const handleCollectionDescriptionChange = (e)=>{
        if(e.target.value === ""){
            setImportant2Empty(true);
        }
        else{
            setCollectionDescription(e.target.value)
        }
    }

    // const EncodeABI = new ethers.utils.Interface(ContratABI)

    const handleSingleSubmit = async ()=>{
        setLoading(true);
        const pinhataResponse = await pinJSONToIPFS(pinData)
        const tokenURI = pinhataResponse.pinataUrl;

        // const transaction = {
        //     to: contractAddress,
        //     from: Wallet.address,
        //     data : EncodeABI.encodeFunctionData('mint',[Wallet.address, tokenURI])
        // }

        let Txn = await ContractInstance.mint(CollectionWallet.address, tokenURI)
        
        console.log(Txn)

        const mintReceipt = await Txn.wait()

        console.log(mintReceipt)
        
        let nft_id = parseInt(mintReceipt.events[0].args[2]._hex, 16)

        let UnitPrice = ethers.utils.parseUnits(price)

        let Txn2 = await ListingContract.listItem(contractAddress, artistWallet, nft_id, UnitPrice)

        const receipt = await Txn2.wait()

        console.log(receipt);

        data.append("nft_id", `${nft_id}`);

        fetch(`https://creath.tech/api/admin/${medium}/arts`, {
            method: "POST",
            headers:{
                Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            body: data
        }).then(response=> response.json())
        .then((data)=>{
            if(data.message === "Art created Successfully!"){
                setLoading(false)
                changePage('/artWorks')
                let notData = new FormData
                notData.append('name',`${JSON.parse(localStorage.getItem("currentUser")).full_name}`)
                notData.append('email',`${ JSON.parse(localStorage.getItem("currentUser")).email}`)
                notData.append('action', "Created an Artwork")
                fetch('https://creath.tech/api/notifications',{
                    method: "POST",
                    headers:{
                        Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
                    },
                    body: notData
                }).then(response => response.json())
                .then((data)=>{console.log(data)})
            }
            else{
                setLoading(false)
            }
        })
        .catch((e)=>{
            setLoading(false)
        })
        
        
    } 

    const handleCollectionSubmit = async ()=>{
        setLoading2(true);
        let Txn = await CollectionContractInstance.createNFTContract(collectionName, (collectionName[0] + collectionName[1]).toUpperCase)

        const receipt = await Txn.wait()
        console.log(receipt)
        data2.append('nft_address', receipt.events[0].address)

        fetch(`https://creath.tech/api/admin/collections`, {
            method: "POST",
            headers:{
                'Authorization':`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
            },
            body: data2
        }).then(response=> response.json())
        .then((data)=>{
            if(data.data.name){
                setLoading2(false);
                props.setCollectionAddress(data.data)
                localStorage.setItem('collection', JSON.stringify(data.data));
                changePage("/addArt2")
                let notData = new FormData
                notData.append('name',`${JSON.parse(localStorage.getItem("currentUser")).full_name}`)
                notData.append('email',`${ JSON.parse(localStorage.getItem("currentUser")).email}`)
                notData.append('action', "Created a collection")
                fetch('https://creath.tech/api/notifications',{
                    method: "POST",
                    headers:{
                        Authorization:`Bearer ${JSON.parse(localStorage.getItem("currentUser")).id}`
                    },
                    body: notData
                }).then(response => response.json())
                .then((data)=>{console.log(data)})
            }

        })
        .catch((e)=>{
            setLoading(false)
        })
    }

    return(
        <div className="mainAddArt1">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="subMainAddArt1">
                <div className="ArtistProfilesHeader">
                    <p>Artist Profile</p>
                </div>
                <div className="ArtistProfileFloW">
                    <p><span>Artist > </span><span> Artist Profile > </span>Upload Art</p>
                </div>
                <div className="formHeaderButtons">
                    <button onClick={()=>{handleTypeClick("single")}} className={type === "single" ? "currentType" : ""}>Single</button>
                    <button onClick={()=>{handleTypeClick("collection")}} className={type === "collection" ? "currentType" : ""}>Collections</button>
                </div>
                <div className="addArtForms">
                    {
                        type === "single" ?
                        <div className="addSingleForms">
                            <div className="addSingleForm1">
                                <div className="input">
                                    <p>Art Name**</p>
                                    <input type="text" placeholder="Art Name" onChange={handleArtNameChange}/>
                                </div>
                                <div className="input">
                                    <p>Art Description**</p>
                                    <input type="text" placeholder="Art Description" onChange={handleDescriptionChange}/>
                                </div>
                                <div className="input">
                                    <p>Art Image**</p>
                                    <input type="text" placeholder="Art Image Link" onChange={handleLinkChange}/>
                                </div>
                                <div className="input">
                                    <p>Physical Copy Availability**</p>
                                    <Select
                                        options={options1}
                                        placeholder="Unavailable"
                                        onChange={handlePhysical}
                                    />
                                </div>
                                <div className="input">
                                    <p>Dimension (CM)**</p>
                                    <input type="text" placeholder="Art Dimension" onChange={handleDimensionChange}/>
                                </div>
                                <div className="input">
                                    <p>Medium**</p>
                                    <Select
                                        options={options2}
                                        placeholder="Select Medium"
                                        onChange={handleMediumChange}
                                    />
                                </div>
                                <div className="input">
                                    <p>Weight (Kg)**</p>
                                    <input type="text" placeholder="Art Weight" onChange={handleWeightChange}/>
                                </div>
                                <div className="input">
                                    <p>Price (USDC)**</p>
                                    <input type="number" placeholder="Art Price" onChange={handlePriceChange} min={0}/>
                                </div>
                                <div className="input">
                                    <p>Location of Artist**</p>
                                    <input type="text" placeholder="Artist Location" onChange={handleLocationChange}/>
                                </div>
                            </div>
                            <div className="addSingleForm2">
                                {
                                    Art ?
                                        Art.type.includes("mp4") || Art.type.includes("mkv") ?
                                            <video className="artMedia"  autoPlay={true} muted={true} loop>
                                                <source src={URL.createObjectURL(Art)} />
                                            </video>
                                        :
                                            <img className="artMedia"  src={URL.createObjectURL(Art)} alt="default Avatar" />
                                    :
                                    <img className="artMedia"  src={defaultImage} alt="default"/>
                                }
                                <input type="file" placeholder="Choose Art" accept=".jpg, .jpeg, .png, .gif, .mp4, .mkv" onChange={fileHandler} name="file_upload"></input>
                                <p className="file_label">Add Art</p>
                            </div>
                            <button disabled = {Art === null || ArtName === "" || Description === "" || physicalCopy === "" || location === ""  || weight === "" || price === "" || dimension === "" ||  medium === "" ? true : false} onClick={()=>{handleSingleSubmit()}} className={loading? "loading" : ""}>Upload Artwork</button>
                        </div>
                        :
                        <div className="addCollectionFoms">
                            <div className="addCollectionForm1">
                                <div className="input">
                                    <p>Collection Name**</p>
                                    <input type="text" placeholder="Collection Name" onChange={handleCollectionNameChange}/>
                                </div>
                                <div className="input">
                                    <p>Collection Description**</p>
                                    <input type="text" placeholder="Collection Description" onChange={handleCollectionDescriptionChange}/>
                                </div>
                                <div className="input">
                                    <p>Collection Cover Link**</p>
                                    <input type="text" placeholder="Collection Cover Image" onChange={fileHandler2}/>
                                </div>
                            </div>
                            <div className="addCollectionForm2">
                            </div>
                            <button onClick={()=>{handleCollectionSubmit()}} className={loading2? "loading" : ""}>Next</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AddArt1;