import axios from "axios";

const key = process.env.REACT_APP_PINATA_KEY;
const secret = process.env.REACT_APP_PINHATA_SECRET_KEY
const jwt  = `Bearer ${process.env.REACT_APP_JWT}`


export const pinJSONToIPFS = async(JSONBody) => {
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
    //making axios POST request to Pinata ⬇️
    return axios({method:"post",url: url,data: JSONBody,
            headers: {
                pinata_api_key: `${key}`,
                pinata_secret_api_key: `${secret}`,
                Authorization: jwt
            }
        })
        .then(function (response) {
           return {
               success: true,
               pinataUrl: "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash
           };
        })
        .catch(function (error) {
            console.log(error)
            return {
                success: false,
                message: error.message,
            }

    });
};