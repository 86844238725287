import React, {useState} from "react";
import Logo from './creath.svg';
import admin from './admins.svg';
import artists from './artworks.svg';
import dashboard from './dashboard.svg';
import settings from './settings.svg';
import artworks from './artists.svg';
import notification from './notification.svg'
import admin_black from './admin_black.svg';
import artists_black from './artist_black.svg';
import dashboard_black from './dashbaord_black.svg';
import settings_black from './settings_black.svg';
import artworks_black from './artworks_black.svg';
import notification_black from './notification_black.svg'
import { useNavigate } from 'react-router-dom';
import './sidebar.css'


const MainSideBar = (props)=>{
    const [currentHover, setCurrentHover] = useState("")

    let navigate = useNavigate();
    const setHover = (n)=>{
        setCurrentHover(n)
    }

    const handleMenuClick = (n)=>{
        props.setCurrentMenu(n)
        navigate(`/${n}`)
    }



    return(
        <div className="Sidebar">
            <img src={Logo} className="sidebarCreath" alt="Creath: Where Digital Meets Creativity"/>'
            <div className="Menu_Items">
                <div onClick={()=>{handleMenuClick("dashboard")}} onMouseEnter={()=>{setHover("dashboard")}} onMouseLeave={()=>{setHover("")}} className={currentHover === "dashboard" ? "Item hovering" : "Item"} id = {props.currentMenu === "dashboard" ? "selected" : ""}>
                    <img src={currentHover === "dashboard" || props.currentMenu === "dashboard" ? dashboard_black : dashboard} alt="Dashboard"/>
                    <p>Dashboard</p>
                </div>
                <div onClick={()=>{handleMenuClick("artists")}} onMouseEnter={()=>{setHover("artists")}} onMouseLeave={()=>{setHover("")}} className={currentHover === "artists" ? "Item hovering" : "Item"} id = {props.currentMenu === "artists" ? "selected" : ""}>
                    <img src={currentHover === "artists" || props.currentMenu === "artists" ? artists_black : artists} alt="Artists"/>
                    <p>Artists</p>
                </div>
                <div onClick={()=>{handleMenuClick("artworks")}} onMouseEnter={()=>{setHover("artworks")}} onMouseLeave={()=>{setHover("")}} className={currentHover === "artworks" ? "Item hovering" : "Item"} id = {props.currentMenu === "artworks" ? "selected" : ""}>
                    <img src={currentHover === "artworks" || props.currentMenu === "artworks" ? artworks_black : artworks} alt="artworks"/>
                    <p>Artworks</p>
                </div>
                <div onClick={()=>{handleMenuClick("admins")}} onMouseEnter={()=>{setHover("admins")}} onMouseLeave={()=>{setHover("")}} className={currentHover === "admins" ? "Item hovering" : "Item"} id = {props.currentMenu === "admins" ? "selected" : ""}>
                    <img src={currentHover === "admins" || props.currentMenu === "admins" ? admin_black : admin} alt="admins"/>
                    <p>Admins</p>
                </div>
                <div onClick={()=>{handleMenuClick("notifications")}} onMouseEnter={()=>{setHover("notifications")}} onMouseLeave={()=>{setHover("")}} className={currentHover === "notifications" ? "Item hovering" : "Item"} id = {props.currentMenu === "notifications" ? "selected" : ""}>
                    <img src={currentHover === "notifications" || props.currentMenu === "notifications" ? notification_black : notification} alt="notifications"/>
                    <p>Notifications <span>.</span></p>
                </div>
                <div onClick={()=>{handleMenuClick("settings")}} onMouseEnter={()=>{setHover("settings")}} onMouseLeave={()=>{setHover("")}} className={currentHover === "settings" ? "Item hovering" : "Item"} id = {props.currentMenu === "settings" ? "selected" : ""}>
                    <img src={currentHover === "settings" || props.currentMenu === "settings" ? settings_black : settings} alt="settings"/>
                    <p>Settings</p>
                </div>
            </div>
        </div>  
    )
}


export default MainSideBar;