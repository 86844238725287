import React, { useEffect, useState } from "react";
import MainSideBar from "../../Components/Sidebar/sidebar";
import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import './artworks.css';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';



const ArtWorks = (props)=>{
    const column1 = [
        {
            field: 'id', headerName: 'ID', type: 'number'
        },
        {
            field: 'name', headerName: 'Art Name',width: 200
        },
        {
            field: 'description', headerName: 'Art Description',width: 200
        },
        {
            field: 'dimension', headerName: 'Dimension (CM)',width: 200
        },
        {
            field: 'weight', headerName: 'Weight',width: 200
        },
        {
            field: 'has_physical_copy', headerName: 'Physical Copy',width: 200
        },
        {
            field: 'artist_name', headerName: 'Creator',width: 200
        },
        {
            field: 'owner', headerName: 'Owner',width: 200
        },
        {
            field: 'price', headerName: 'Art Price(USDC)',width: 200
        },
        {
            field: 'created_at', headerName: 'Created At', type: 'date',width: 300
        },
        {
            field: 'updated_at', headerName: 'Updated At', type: 'date',width: 300
        }
    ]

    const column2 = [
        {
            field: 'id', headerName: 'ID', type: 'number'
        },
        {
            field: 'name', headerName: 'Collection Name',width: 200
        },
        {
            field: 'description', headerName: 'Description',width: 200
        },
        {
            field: 'number_of_arts', headerName: 'Number of Arts',width: 200
        },
        {
            field: 'created_at', headerName: 'Created At', type: 'date',width: 300
        },
        {
            field: 'updated_at', headerName: 'Updated At', type: 'date',width: 300
        }
    ]

    const column3 = [
        {
            field: 'id', headerName: 'ID', type: 'number'
        },
        {
            field: 'name', headerName: 'Exhibition Name',width: 200
        },
        {
            field: 'description', headerName: 'Description',width: 200
        },
        {
            field: 'type', headerName: 'Type',width: 200
        },
        {
            field: 'created_at', headerName: 'Created At', type: 'date',width: 300
        },
        {
            field: 'updated_at', headerName: 'Updated At', type: 'date',width: 300
        }
    ]
    const [artists, setArtists] = useState([]);
    // const [empty, setEmpty] = useState(false);
    const [loadStatus, setLoadStatus] = useState(true)
    const [pageSize, setPageSize]  = useState(8)
    const [column, setColumn] = useState(column1)
    const [row, setRow] = useState(props.Arts)
    const [currentRow, setCurrentRow] = useState("NFT")


    const options = [
       {value: 'NFT', label: 'Artworks'},
       {value:'collections', label: 'Collections'},
       {value:'exhibitions', label:'Exhibitions'}
    ]
    useEffect(()=>{
        props.setCurrentMenu("artworks")

        fetch("https://creath.tech/api/artists" )
        .then(response => response.json())
        .then((data)=>{
            if(data.data.length > 0){
                setArtists(data.data)
                setLoadStatus(false)
            }
            else{
                // setEmpty(true)
                setLoadStatus(false)
            }
        })
        .catch((e)=>{
            console.log(e)
        })
    },[])

    useEffect(()=>{
        if(currentRow === "NFT"){
            setColumn(column1)
            setRow(JSON.parse(localStorage.getItem("arts")))
        }
        else if(currentRow === "collections"){
            setColumn(column2)
            setRow(JSON.parse(localStorage.getItem("collections")))
        }
        else{
            setColumn(column3)
            setRow(JSON.parse(localStorage.getItem("exhibitions")))
        }
    },[currentRow])

    let navigate = useNavigate();

    const changePage = (n)=>{
        navigate(n)
    }


    const handleRowClick = (params)=>{
        if(currentRow === "NFT"){
            changePage(`/artProfile?id=${params.row.id}`)
        }
        else if(currentRow === "collections"){
            changePage(`/collectionsProfile?id=${params.row.id}`)
        }
        else{
            changePage(`/exhibitionsProfile?id=${params.row.id}`)
        }
    }

    const handleSelectChange = (selectedOption) =>{
        setCurrentRow(selectedOption.value)
    }


    return(
        <div className="mainArts">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="ArtsSubMain">
                <div className="ArtsHeader">
                    <p>Arts</p>
                    <div>
                        <input></input>
                    </div>
                </div>
                <div className="ArtsCards">
                    <div className="card">
                        <p className="item">Artists</p>
                        <p className="value">{artists.length}</p>
                    </div>
                    <div className="card">
                        <p className="item">Artworks</p>
                        <p className="value">{JSON.parse(localStorage.getItem('arts')).length}</p>
                    </div>
                </div>
                <div className="ArtsListing">
                    <div className="ArtsListingHeader">
                        <p>Arts Listings</p>
                        <Select
                            defaultValue={currentRow}
                            onChange={handleSelectChange}
                            options={options}
                        />
                    </div>
                    <button className="addExhiButton" onClick={()=>{changePage('/addExhibition')}}>Add Exhibition</button>
                </div>
                <div className="ArtGrid">
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            columns={column}
                            rows={row}
                            loading={loadStatus}
                            pageSize = {pageSize}
                            onPageSizeChange = {(newPageSize)=>setPageSize(newPageSize)}
                            rowsPerPageOptions = {[8, 16, 24]}
                            autoHeight
                            pagination
                            onRowClick={handleRowClick}
                        />
                    </Box>
                </div>
            </div>
        </div>
    )
}


export default ArtWorks;