import React, { useEffect, useState } from "react";
import MainSideBar from "../../Components/Sidebar/sidebar";
import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import './artists.css';
import { useNavigate } from 'react-router-dom';



const Artists = (props)=>{
    const [artists, setArtists] = useState([]);
    // const [empty, setEmpty] = useState(false);
    const [loadStatus, setLoadStatus] = useState(true)
    const [pageSize, setPageSize]  = useState(8)


    useEffect(()=>{
        props.setCurrentMenu("artists")

        fetch("https://creath.tech/api/artists" )
        .then(response => response.json())
        .then((data)=>{
            if(data.data.length > 0){
                setArtists(data.data)
                setLoadStatus(false)
            }
            else{
                // setEmpty(true)
                setLoadStatus(false)
            }
        })
        .catch((e)=>{
            console.log(e)
        })
    },[])

    let navigate = useNavigate();

    const changePage = (n)=>{
        navigate(n)
    }


    const column = [
        {
            field: 'id', headerName: 'ID', type: 'number'
        },
        {
            field: 'full_name', headerName: 'Name',width: 200
        },
        {
            field: 'username', headerName: "UserName", width:200
        },
        {
            field: 'email', headerName: 'Email',width: 200
        },
        {
            field: 'country', headerName: 'Country',width: 200
        },
        {
            field: 'state', headerName: 'State',width: 200
        },
        {
            field: 'twitter', headerName: 'Twitter',width: 200
        },
        {
            field: 'instagram', headerName: 'Instagram',width: 200
        },
        {
            field: 'wallet', headerName: 'Wallet',width: 200
        },
        {
            field: 'created_at', headerName: 'Date', type: 'date',width: 300
        }
    ]


    const handleRowClick = (params)=>{
        props.setCurrentArtist(params.row)
        changePage('/artistProfile')
        localStorage.setItem('currentArtist', JSON.stringify(params.row))
    }


    return(
        <div className="mainArtists">
            <MainSideBar setCurrentMenu = {props.setCurrentMenu} currentMenu = {props.currentMenu}/>
            <div className="ArtistsSubMain">
                <div className="ArtistsHeader">
                    <p>Artists</p>
                    <div>
                        <input></input>
                    </div>
                </div>
                <div className="ArtistsCards">
                    <div className="card">
                        <p className="item"> Artists</p>
                        <p className="value">{artists.length}</p>
                    </div>
                    <div className="card">
                        <p className="item">Artworks</p>
                        <p className="value">{props.Arts.length}</p>
                    </div>
                </div>
                <div className="artistsListing">
                    <div className="artistsListingHeader">
                        <p>Artist Listings</p>
                        <button onClick={()=>{changePage('/addArtist')}}>Add Artist</button>
                    </div>
                </div>
                <div className="artistGrid">
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            columns={column}
                            rows={artists}
                            loading={loadStatus}
                            pageSize = {pageSize}
                            onPageSizeChange = {(newPageSize)=>setPageSize(newPageSize)}
                            rowsPerPageOptions = {[8, 16, 24]}
                            autoHeight
                            pagination
                            onRowClick={handleRowClick}
                        />
                    </Box>
                </div>
            </div>
        </div>
    )
}


export default Artists